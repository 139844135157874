import { CSSObject } from "@emotion/react"
import { CSSProperties } from "react"
import { ResponsiveVariantStyles, scaleValue } from "../helpers/css"

export const xsScaleFactor = 1
export const mdScaleFactor = 0.8
export const lgScaleFactor = 0.8
export const xlScaleFactor = 0.9

export const pageWidth = {
    xs: {
        width: "100%",
        maxWidth: 1296,
        paddingLeft: 16,
        paddingRight: 16,
        scrollPaddingLeft: 16,
        scrollPaddingRight: 16,
    },
    md: {
        paddingLeft: 24,
        paddingRight: 24,
        scrollPaddingLeft: 24,
        scrollPaddingRight: 24,
    },
} as const

export const pageSize = {
    xs: { ...pageWidth.xs, paddingBottom: 16 },
    md: { ...pageWidth.md, paddingBottom: 24 },
} as const

/**
 * A set of sizes shared throughout the component library to have a consistent way of specifying
 * sizes. Not all sizes will be used in all contexts, for example border radiuses are defined as
 * four sizes and buttons come in two sizes.
 */
export type Size = "xs" | "sm" | "md" | "lg" | "xl"

/**
 * Each breakpoint is from the property value and up to the next, e.g. xs
 * screen is from 0-480px, and xl screen is from 1200 and up.
 */
export const screenSizes = {
    xs: 0,
    sm: 480,
    md: 840,
    lg: 1080,
    xl: 1260,
} as const

export type ScreenSize = keyof typeof screenSizes

/**
 * The design system has defined four different border radius sizes (xs-lg)
 */
export type BorderRadiusVariant = Exclude<Size, "xl">

/**
 * Defines the border radius levels for different screen sizes. E.g. an element with md border
 * size has 16px border radius on xs screens and 24px border radius on md screens.
 */
export const borderRadiusVariants: Partial<
    Record<BorderRadiusVariant, Partial<Record<ScreenSize, number>>>
> = {
    xs: { xs: 6 },
    sm: { xs: 12 },
    md: { xs: 16, md: 24 },
    lg: { xs: 24, md: 32 },
}

export type HeadingSize = "1" | "2" | "3" | "4" | "5"
export type FontWeight = 300 | 400 | 500 | 600

/**
 * The heading sizes for different screen sizes. Larger screens will inherit from smaller screens,
 * so only need to specify overrides for these.
 */
export const headingSizes: ResponsiveVariantStyles<HeadingSize> = {
    "1": {
        xs: {
            fontSize: 28 * xlScaleFactor,
            fontWeight: 500,
            lineHeight: "100%",
            letterSpacing: -0.6,
        },
        md: {
            fontSize: 40 * xlScaleFactor,
            letterSpacing: -0.8,
        },
        lg: {
            fontSize: 54 * xlScaleFactor,
            letterSpacing: -0.8,
        },
    },
    "2": {
        xs: {
            fontSize: 24 * xlScaleFactor,
            fontWeight: 400,
            lineHeight: "110%",
            letterSpacing: 0.24,
        },
        md: {
            fontSize: 32 * xlScaleFactor,
            letterSpacing: 0.3,
        },
        lg: {
            fontSize: 40 * xlScaleFactor,
            letterSpacing: 0.4,
        },
    },
    "3": {
        xs: { fontSize: 18 * xlScaleFactor, fontWeight: 400, lineHeight: "130%" },
        md: { fontSize: 24 * xlScaleFactor },
        lg: { fontSize: 28 * xlScaleFactor },
    },
    "4": {
        xs: {
            fontSize: 15,
            fontWeight: 600,
            lineHeight: "120%",
            letterSpacing: -0.15,
        },
        md: {
            fontSize: 16 * xlScaleFactor,
            fontWeight: 500,
        },
        lg: {
            fontSize: 18 * xlScaleFactor,
            letterSpacing: -0.18,
        },
    },
    "5": {
        xs: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "110%",
            letterSpacing: -0.14,
        },
        md: {
            fontSize: 15 * xlScaleFactor,
        },
        lg: {
            fontSize: 16 * xlScaleFactor,
            letterSpacing: -0.18,
        },
    },
}

/**
 * There's a body size for every size
 */
export type BodySize = Size

export const bodySizes: ResponsiveVariantStyles<BodySize> = {
    xs: {
        xs: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "135%",
        },
        md: {
            fontSize: 15,
            fontWeight: 300,
        },
    },
    sm: {
        xs: {
            fontSize: 15,
            fontWeight: 400,
            lineHeight: "135%",
        },
        md: {
            fontSize: 16 * xlScaleFactor,
            fontWeight: 300,
        },
        xl: {
            fontSize: 17 * xlScaleFactor,
        },
    },
    md: {
        xs: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "135%",
        },
        md: {
            fontSize: 17 * xlScaleFactor,
            fontWeight: 300,
        },
        xl: { fontSize: 18 * xlScaleFactor },
    },
    lg: {
        xs: {
            fontSize: 17,
            fontWeight: 300,
            lineHeight: "135%",
        },
        md: { fontSize: 22 * lgScaleFactor },
        xl: { fontSize: 22 * xlScaleFactor },
    },
    xl: {
        xs: {
            fontSize: 18,
            fontWeight: 300,
            lineHeight: "130%",
        },
        md: {
            fontSize: 21 * xlScaleFactor,
        },
        lg: { fontSize: 28 * lgScaleFactor },
        xl: { fontSize: 28 * xlScaleFactor },
    },
}

/**
 * Labels are only defined in a single size in the design system.
 */
export type LabelSize = Extract<Size, "md">

export const labelSizes: Record<LabelSize, Partial<Record<ScreenSize, CSSProperties>>> = {
    md: {
        xs: {
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: 0.96,
            textTransform: "uppercase",
        },
        md: {
            fontSize: 16,
        },
    },
}

export type ButtonSize = "xs" | "sm" | "md"

/**
 * The button sizes for different screen sizes. Larger screens will inherit from smaller screens,
 * so only need to specify overrides for these.
 */
export const buttonSizes: Record<ButtonSize, Partial<Record<ScreenSize, CSSObject>>> = {
    xs: {
        xs: {
            height: 36 * xlScaleFactor,
            padding: `0 ${12 * xlScaleFactor}px`,
        },
    },
    sm: {
        xs: {
            height: 48 * xlScaleFactor,
            padding: `0 ${24 * xlScaleFactor}px`,
        },
    },
    md: {
        xs: {
            height: 48 * xlScaleFactor,
            padding: `0 ${24 * xlScaleFactor}px`,
        },
        lg: {
            height: 64 * xlScaleFactor,
            padding: `0 ${32 * xlScaleFactor}px`,
        },
    },
}

/**
 * The button sizes for buttons with icon only, for different screen sizes. Larger screens will
 * inherit from smaller screens, so only need to specify overrides for these.
 */
export const buttonIconOnlySizes: Record<ButtonSize, Partial<Record<ScreenSize, CSSObject>>> = {
    xs: {
        xs: {
            height: 36 * xlScaleFactor,
            width: 36 * xlScaleFactor,
            svg: { height: 16, width: 16 },
        },
    },
    sm: {
        xs: {
            height: 48 * xlScaleFactor,
            width: 48 * xlScaleFactor,
        },
    },
    md: {
        xs: {
            height: 48 * xlScaleFactor,
            width: 48 * xlScaleFactor,
        },
        lg: {
            height: 64 * xlScaleFactor,
            width: 64 * xlScaleFactor,
        },
    },
}

export type FixedSpacingSize = "xs" | "sm" | "md" | "lg" | "xl"
export const fixedSpacingSizes = {
    xs: 8,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 40,
} as const

export type SpacingSize = "xs" | "sm" | "md" | "lg" | "xl"
export const spacingSizes: Record<SpacingSize, Partial<Record<ScreenSize, number>>> = {
    xs: {
        xs: 4,
        sm: 12,
        md: 18,
        lg: 24,
    },
    sm: {
        xs: 16,
        lg: 22,
    },
    md: {
        xs: 16,
        sm: 22,
        md: 24,
        lg: 24,
        xl: 24,
    },
    lg: {
        xs: 24,
        sm: 32,
        md: 56,
        lg: 60,
        xl: 64,
    },
    xl: {
        xs: 40,
        sm: 56,
        md: 72,
        lg: 80,
        xl: 96,
    },
}
export function isSpacingSize(val: string | number): val is SpacingSize {
    if (typeof val === "number") return false
    return Object.keys(spacingSizes).includes(val)
}

export const sectionSpacing = {
    xs: scaleValue(36),
    md: scaleValue(48),
}
