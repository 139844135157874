import { ChangeEvent, useCallback, useMemo } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { Locale, LocaleKey } from "../../../../../../packages/localization/Locale"
import { Localized } from "../../../../../../packages/localization/Localized"
import { Flag } from "../visual/Flag"
import { Dropdown } from "../buttons/Dropdown"

export function LocaleSelect(props: {
    locales: Locale[]
    currentLocale: LocaleKey
    onLocaleChange?: (locale: LocaleKey) => void
}) {
    const options = useMemo(
        () =>
            props.locales.map((l) => ({
                value: l.key.toString(),
                // Is typed as Localized<string> but is already localized in API.
                text: l.displayName as any as string,
            })),
        [props.locales]
    )

    const handleSelectChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            props.onLocaleChange?.(e.target.value as any as LocaleKey)
        },
        [props]
    )

    return (
        <Dropdown
            options={options}
            value={props.currentLocale.toString()}
            onChange={handleSelectChange}
            renderValue={(val) => (val === "en" || val === "no" ? <Flag flag={val} /> : null)}
        />
    )
}

Component(LocaleSelect, {
    name: "LocaleSelect",
    gallery: {
        path: "Controllers/LocaleSelect",
        items: [
            {
                variants: [
                    {
                        props: {
                            currentLocale: LocaleKey("no"),
                            onLocaleChange: (l: LocaleKey) => {
                                // eslint-disable-next-line no-console
                                console.log("Changed locale to", l)
                            },
                            locales: [
                                {
                                    flag: "🇬🇧",
                                    key: LocaleKey("en"),
                                    displayName: "English" as any as Localized<string>,
                                    fallback: LocaleKey("no"),
                                },
                                {
                                    flag: "🇳🇴",
                                    key: LocaleKey("no"),
                                    displayName: "Norwegian" as any as Localized<string>,
                                },
                            ],
                        },
                    },
                ],
            },
        ],
    },
})
