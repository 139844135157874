import { SpacingSize } from "../ui/constants/sizes"
import { Divider as DividerComponent } from "../ui/components/other/Divider"
import { Section } from "../../../../packages/editing/Section"

/**
 * @icon ui-divider
 * @param section
 * @returns
 */
function ContentDivider(section: {
    /**
     * Spacing above and below the divider.
     */
    spacing: SpacingSize
}) {
    return <DividerComponent horizontal spacing={section.spacing} />
}
Section(ContentDivider)
