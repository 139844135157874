import { SerializedStyles } from "@emotion/serialize"
import { SVGProps } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { colors } from "../../constants/colors"

export function RedoitStar({ css, ...rest }: { css?: SerializedStyles } & SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            {...rest}
        >
            <path
                fill={colors.forest}
                d="M0 0v24h24V0H0Zm20.305 10.27c-1.473 1.25-2.946 2.5-4.424 3.745-.103.087-.111.162-.082.282.488 1.907.976 3.81 1.46 5.718.013.046.017.095.03.162-.071-.042-.12-.07-.175-.104-1.65-1.043-3.302-2.085-4.949-3.132-.111-.07-.19-.079-.306-.004-1.642 1.046-3.29 2.085-4.936 3.124-.054.033-.112.07-.203.124.178-.695.343-1.362.513-2.028.327-1.29.654-2.578.99-3.865.032-.124.016-.194-.084-.281-1.485-1.254-2.967-2.512-4.448-3.77-.033-.029-.062-.058-.124-.111.256-.021.476-.038.7-.05 1.245-.083 2.494-.161 3.74-.244.525-.033 1.055-.075 1.58-.1.125-.004.137-.086.17-.165l1.531-3.844c.215-.538.426-1.072.642-1.61.02-.054.045-.107.082-.186l.075.17c.724 1.825 1.452 3.645 2.176 5.47.046.12.108.161.236.17 1.457.09 2.913.19 4.366.285.414.03.823.05 1.237.079.108.008.215.016.356.029-.066.058-.108.1-.153.136Z"
            />
        </svg>
    )
}

export function RedoitStars(props: { rating: number }) {
    if (props.rating < 0 || props.rating > 5) {
        console.warn("Prop `rating` should be between 1 and 5.")
    }

    return (
        <div style={{ display: "grid", gap: 2, gridAutoFlow: "column" }}>
            {[1, 2, 3, 4, 5].map((i) => (
                <div
                    key={i}
                    style={{
                        display: "flex",
                        height: 20,
                        width: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: colors.grayWhite,
                    }}
                >
                    <RedoitStar style={{ height: "100%", width: "100%" }} />
                </div>
            ))}
        </div>
    )
}

Component(RedoitStars, {
    name: "RedoitStars",
    gallery: {
        items: [
            {
                variants: [
                    { props: { rating: 1 } },
                    { props: { rating: 2 } },
                    { props: { rating: 3 } },
                    { props: { rating: 4 } },
                    { props: { rating: 5 } },
                ],
            },
        ],
    },
})
