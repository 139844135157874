import { cloneElement, useEffect, useMemo, useRef, useState } from "react"
import { keyframes } from "@emotion/react"
import { css } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { Uuid } from "../../../../../../reactor"
import { responsiveSpacing } from "../../helpers/css"
import { ReviewCard, ReviewCardProps } from "../cards/ReviewCard"

const defaultSpeed = 30

/**
 * To get the continuous loop, we need to render an extra set of items. They are hidden from
 * screen readers with aria-hidden.
 */
export function ReviewsCarousel(props: {
    /**
     * Carousel speed in pixels per second.
     */
    speed?: number

    direction?: "row" | "column"

    reviews: {
        id: string
        rating: number
        name: string
        title: string
        text: string
        timestamp: string
    }[]

    ReviewCardProps?: Partial<ReviewCardProps>
}) {
    const ref = useRef<HTMLDivElement>(null)
    const [size, setSize] = useState(0)
    const direction = props.direction ?? "row"

    useEffect(() => {
        const rect = ref.current?.getBoundingClientRect()
        if (rect) {
            setSize(rect[direction === "row" ? "width" : "height"])
        }
    }, [direction])

    const animationDuration = useMemo(
        () => `${(size / (props.speed ?? defaultSpeed)) * 1000}ms`,
        [props.speed, size]
    )

    const reviewElements = useMemo(
        () =>
            cloneElement(
                <>
                    {props.reviews.map(({ id, ...review }) => (
                        <div
                            key={id}
                            css={css(
                                responsiveSpacing(
                                    "sm",
                                    direction === "row" ? "marginLeft" : "marginTop"
                                )
                            )}
                        >
                            <ReviewCard {...review} {...(props.ReviewCardProps ?? {})} />
                        </div>
                    ))}
                </>
            ),
        [props.ReviewCardProps, direction, props.reviews]
    )

    const animationCss = css({
        display: "flex",
        flexDirection: direction,
        animationName: direction === "row" ? horizontalScroll : verticalScroll,
    })

    return (
        <div
            style={{
                position: "relative",
                flexWrap: "nowrap",
                display: "flex",
                flexDirection: direction,
                [direction === "row" ? "width" : "height"]: "100%",
            }}
            css={containerCss}
        >
            <div
                ref={ref}
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
            <div
                aria-hidden
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
            <div
                aria-hidden
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
            <div
                aria-hidden
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
            <div
                aria-hidden
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
        </div>
    )
}

const verticalScroll = keyframes({
    from: {
        transform: "translateY(-200%)",
    },
    to: {
        transform: "translateY(-300%)",
    },
})

const horizontalScroll = keyframes({
    from: {
        transform: "translateX(-200%)",
    },
    to: {
        transform: "translateX(-300%)",
    },
})

const responsiveGridCss = css({
    display: "inline-flex",
    alignItems: "center",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
})

const containerCss = css({
    "&:hover div": {
        animationPlayState: "paused",
    },
})

Component(ReviewsCarousel, {
    name: "ReviewsCarousel",
    gallery: {
        path: "Carousel",
        items: [
            {
                variants: [
                    {
                        props: {
                            reviews: [...new Array(3)].map((_, index, arr) => ({
                                id: Uuid().valueOf(),
                                title: "Impressive!",
                                rating: 5,
                                name: "John",
                                text: "This service was so impressive, I won't hesitate to use it again.",
                                timestamp: `${arr.length - index} days ago`,
                            })),
                        },
                    },
                ],
            },
        ],
    },
})
