/**
 * The animation defined in the design system in Figma is set with the physical attributes
 * stiffness: 720, damping: 60, and mass: 1. This is roughly translated to the following
 * cubic-bezier function, which probably needs some adjustments, or be replaced by Framer
 * motion, where we can set the intended variables.
 */
const defaultDuration = 200

export const transitionTimingFunction = "cubic-bezier(0.4, 0, 0.2, 1)"

export const defaultTransition = `all ${defaultDuration}ms ${transitionTimingFunction}`

/**
 * Helper function to get a transition.
 *
 * @param props Which CSS properties to animate.
 * @param duration Duration in milliseconds.
 * @returns CSS transition property value.
 */
export function getTransition(props: string[] = ["all"], duration: number = defaultDuration) {
    return `${props.join(" ")} ${duration}ms ${transitionTimingFunction}`
}

export const componentAnimations = {
    card: { type: "spring", stiffness: 1400, damping: 100, mass: 1 },
    carousel: { type: "spring", duration: 0.9, bounce: 0, velocity: 6 },
}

export const springAnimations = {
    "200": { type: "spring", stiffness: 720, damping: 60, mass: 1 },
    "400": { type: "spring", stiffness: 180, damping: 30, mass: 1 },
    "600": { type: "spring", stiffness: 80, damping: 20, mass: 1 },
}
