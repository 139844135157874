import { useLocation } from "react-router-dom"
import { useLogInWithVipps } from "../../../../../packages/oidc/code-flow/VippsLogin"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../../packages/localization/Localized"
import { VippsButton, VippsLogo } from "../../ui/components/visual/VippsButton"
import { Flex } from "../../ui/components/base/Flex"
import { Text } from "../../ui/components/typography/Text"
import { css, responsiveCss } from "../../ui/helpers/css"
import { Heading } from "../../ui/components/typography/Heading"
import { useMe } from "../../client"
import { Markdown } from "../../../../../reactor"
import { MustacheString } from "../../../../../packages/editing/Mustache"
import { Button } from "../../ui/components/buttons/Button"
import { useCheckoutContext } from "./CheckoutContext"

export type LoginStepProps = {
    /**
     * Name of this step, used in checkout step navigation bar, and possibly other places the
     * step is referenced, like the summary page.
     *
     * @default '{"en": "Login", "no": "Logg inn"}'
     */
    name: Localized<string>

    /**
     * Login step identifier to use in the URL.
     * @default '{"en": "login", "no": "logg-inn"}'
     */
    slug: Localized<string>

    /**
     * @default '{"en": "Create new account"}'
     */
    headline: Localized<string>

    /**
     * @default '{"en": "Already have an account? Log in here"}'
     */
    subHeadline: Localized<string>

    /**
     * @default '{"en": "Continue with", "no": "Fortsett med"}'
     */
    registerButton: Localized<string>

    /**
     * Texts to show if user is already logged in.
     */
    loggedIn: {
        /**
         * Available variables are `{{givenName}}` and `{{familyName}}`
         * @default '{"no": "Hei, {{givenName}}"}'
         */
        headline: Localized<string>

        /**
         * Text below the headline. Available variables are `{{givenName}}` and `{{familyName}}`.
         * @default '{"no": "Du er allerede logget inn, og kan fortsette til neste steg."}'
         */
        text: Localized<string>

        /**
         * @default '{"no": "Gå videre"}'
         */
        nextStepButtonText: Localized<string>
    }
}
export function LoginStep(props: LoginStepProps) {
    const { setStep } = useCheckoutContext()
    const localize = useLocalize()
    const { pathname } = useLocation()
    const login = useLogInWithVipps(`${pathname}?auth=success`, `${pathname}?auth=failed`)
    const me = useMe()

    if (me.loading) {
        return <></>
    }

    if (!me.data?.anonymous) {
        return (
            <Flex justifyContent="center" style={{ width: "100%", height: "100%" }}>
                <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    css={css(responsiveCss("max", "sm", { padding: "16px 0" }))}
                >
                    <Flex
                        direction="column"
                        alignItems="center"
                        gap={12}
                        css={css(
                            responsiveCss("max", "sm", { gap: 8, marginBottom: 32 }),
                            responsiveCss("min", "md", {
                                padding: "0 40px",
                                gap: 12,
                                marginBottom: 40,
                            })
                        )}
                    >
                        <Heading color="gray500" level={2}>
                            {MustacheString(localize(props.loggedIn.headline), {
                                givenName: me.data?.givenName ?? "",
                                familyName: me.data?.familyName ?? "",
                            })}
                        </Heading>
                        <Text color="gray400" variant="body" size="md">
                            {MustacheString(localize(props.loggedIn.text), {
                                givenName: me.data?.givenName ?? "",
                                familyName: me.data?.familyName ?? "",
                            })}
                        </Text>
                    </Flex>
                    <Button variant="primary" size="md" onClick={() => setStep("Shipping")}>
                        {localize(props.loggedIn.nextStepButtonText)}
                    </Button>
                </Flex>
            </Flex>
        )
    }

    return (
        <Flex justifyContent="center" style={{ width: "100%", height: "100%" }}>
            <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                css={css(responsiveCss("max", "sm", { padding: "16px 0" }))}
            >
                <div style={{ marginBottom: 24 }}>
                    <VippsLogo width={80} />
                </div>
                <Flex
                    direction="column"
                    alignItems="center"
                    gap={12}
                    css={css(
                        responsiveCss("max", "sm", { gap: 8, marginBottom: 32 }),
                        responsiveCss("min", "md", { padding: "0 40px", gap: 12, marginBottom: 40 })
                    )}
                >
                    <Heading color="gray500" level={2}>
                        {localize(props.headline)}
                    </Heading>
                    <Text color="gray400" variant="body" size="md">
                        {localize(props.subHeadline)}
                    </Text>
                </Flex>
                <VippsButton
                    onClick={() => {
                        void login()
                    }}
                />
            </Flex>
        </Flex>
    )
}
