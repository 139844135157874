import { Component } from "../../../../../../packages/editing/Component"
import { CurrencyCode } from "../../../../../../reactor"
import { Flex } from "../base/Flex"
import { LabelNotification } from "../notification/LabelNotification"
import { Text } from "../typography/Text"

export function PaymentSummary(props: {
    itemName: string
    label: string
    text: string
    originalPrice: number
    discountedPrice: number
    rentalPeriodUnit: string
    currency: string
}) {
    return (
        <Flex borderColor="gray200" borderRadius="md" padding="md" direction="column" gap={8}>
            <Flex justifyContent="space-between" alignItems="flex-start">
                <div>
                    <Text variant="heading" level="4">
                        {props.itemName}
                    </Text>
                    <Flex alignItems="baseline" gap={2}>
                        {props.originalPrice === props.discountedPrice ? null : (
                            <Text
                                variant="body"
                                size="md"
                                color="gray300"
                                style={{
                                    textDecoration: "underline",
                                    textUnderlineOffset: "-5px",
                                    textDecorationSkipInk: "none",
                                }}
                            >
                                {props.originalPrice}
                            </Text>
                        )}
                        <Text variant="heading" level="3" color="brand">
                            {props.discountedPrice} {props.currency}/{props.rentalPeriodUnit}
                        </Text>
                    </Flex>
                </div>
                <LabelNotification color="gray500" size={["fixed", "xs", "md"]}>
                    {props.label}
                </LabelNotification>
            </Flex>
            <Text variant="body" size="sm" color="gray300">
                {props.text}
            </Text>
        </Flex>
    )
}

Component(PaymentSummary, {
    name: "PaymentSummary",
    gallery: {
        path: "Forms/PaymentSummary",
        items: [
            {
                variants: [
                    {
                        props: {
                            itemName: "iPhone 15 Pro",
                            label: "Etter innbytte",
                            text: "Vår estimerte telefonverdi er 3 000 NOK. Dette betyr at du vil få 50 NOK i avslag per måned på avtalen din de neste 24 månedene etter at du har byttet inn enheten din.",
                            originalPrice: 300,
                            discountedPrice: 250,
                            rentalPeriodUnit: "md",
                            currency: CurrencyCode("NOK"),
                        },
                    },
                ],
            },
        ],
    },
})
