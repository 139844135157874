import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { Markdown, Uuid } from "../../../../reactor"
import { Flex } from "../ui/components/base/Flex"
import { Icon, IconName } from "../ui/components/visual/Icon"
import { BodySize } from "../ui/constants/sizes"
import { resetStyles, scaleValue } from "../ui/helpers/css"
import { Text } from "../ui/components/typography/Text"
import { ContentSection, contentSectionCss } from "./Content"
import { MarkdownView } from "../ui/components/base/MarkdownView"

interface ListWithIconsContentProps extends ContentSection {
    /**
     * @default md
     */
    bodyTextSize: BodySize

    /**
     * @expand
     */
    items: {
        readonly id: Uuid
        icon: IconName

        /**
         * @title
         */
        text: Localized<Markdown>
    }[]
}

Section("ListWithIconsContent", ListWithIconsContent)
/**
 * @icon ui-dotpoints-02
 */
function ListWithIconsContent(section: ListWithIconsContentProps) {
    const localize = useLocalize()

    return (
        <Text variant="body" size={section.bodyTextSize} css={css([contentSectionCss(section)])}>
            <ul
                css={css(resetStyles("ul"))}
                style={{ display: "flex", flexDirection: "column", gap: scaleValue(24) }}
            >
                {section.items.map((li) => (
                    <li css={resetStyles("li")} key={li.id.valueOf()}>
                        <Flex alignItems="center" gap={24}>
                            <Flex
                                style={{ flex: "0 0 auto" }}
                                borderRadius="md"
                                alignItems="center"
                                justifyContent="center"
                                backgroundColor="gray100"
                                padding={scaleValue(24)}
                            >
                                <Icon icon={li.icon} size={48} />
                            </Flex>
                            <MarkdownView
                                value={localize(li.text)}
                                renderers={{
                                    paragraph: (p) => <div>{p.children}</div>,
                                }}
                            />
                        </Flex>
                    </li>
                ))}
            </ul>
        </Text>
    )
}
