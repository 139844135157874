import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { WithOverlayCard } from "../ui/components/cards/WithOverlay"
import { CardsBlock } from "../ui/components/blocks/CardsBlock"
import { useMoreReasonsToRedoit } from "../client"

/**
 * @icon ui-info-square
 */
function MoreReasonsToRedoit(section: {}) {
    const localize = useLocalize()
    const moreReasonsToRedoit = useMoreReasonsToRedoit()
    if (moreReasonsToRedoit.loading || !moreReasonsToRedoit.data) return <></>

    return (
        <CardsBlock
            heading={localize(moreReasonsToRedoit.data.heading)}
            showSlideButtons={true}
            cards={moreReasonsToRedoit.data.cards.map((c) => ({ ...c, id: c.id.valueOf() }))}
            enterAnimation={true}
            renderCard={(c, i) => (
                <WithOverlayCard
                    title={localize(c.heading)}
                    text={localize(c.text)}
                    image={c.image}
                    readMoreAriaPrefix={localize(
                        moreReasonsToRedoit.data?.readMoreAriaPrefix ?? {}
                    )}
                    runningAnimation={i === 0}
                />
            )}
        />
    )
}
Section(MoreReasonsToRedoit)
