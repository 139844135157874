import { css } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { Image, ImageToUrl } from "../../../../../../reactor"
import { server } from "../../../../../../server"
import { Flex } from "../base/Flex"
import { AnimatePresence, motion, MotionConfig } from "framer-motion"
import { RefObject, useCallback, useEffect, useRef, useState } from "react"
import { colors } from "../../constants/colors"
import { responsiveCss, scaleValue } from "../../helpers/css"
import { ResponsiveImage } from "../base/ResponsiveImage"

export function ProductCarousel(props: { images: Image[] }) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const containerRef = useRef<HTMLDivElement>(null)
    const scrollRef = useRef<HTMLDivElement>(null)
    const imageRefs = useRef<RefObject<HTMLDivElement>[]>([])
    const imageRefsMap = useRef<Map<string, HTMLDivElement>>(new Map())
    const handleIntersectionChange: IntersectionObserverCallback = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const intersectingEntry = entries.find((entry) => entry.isIntersecting)
            if (!intersectingEntry) return

            for (const [key, value] of imageRefsMap.current.entries()) {
                if (value === intersectingEntry.target) {
                    const index = props.images.findIndex((ir) => ir.valueOf() === key)
                    setCurrentIndex(index)
                    break
                }
            }
        },
        [setCurrentIndex, props.images]
    )

    // Keep track of which image is the first image, to be able to only scroll into view if first
    // image has actually changed.
    const [firstImage, setFirstImage] = useState<string | undefined>()
    useEffect(() => {
        const fi = props.images[0]?.valueOf()
        if (fi && fi !== firstImage) {
            setFirstImage(fi)

            if ((containerRef.current?.getBoundingClientRect().top ?? 0) > window.scrollY) {
                imageRefsMap.current.get(fi)?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                })
            } else {
                if (scrollRef.current?.scrollLeft) {
                    scrollRef.current.scrollLeft = 0
                }
            }
        }
    }, [firstImage, props.images])

    // Does not use props.images directly, but depend on it to update the observers whenever
    // props.images changes.
    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersectionChange, {
            root: containerRef.current,
            // Reduce threshold to fire a little before the scroll is ended.
            threshold: 0.8,
        })

        props.images.forEach((image) => {
            const ir = imageRefsMap.current.get(image.valueOf())
            if (ir) {
                observer.observe(ir)
            }
        })

        return () => {
            observer.disconnect()
        }
    }, [handleIntersectionChange, containerRef, imageRefs, props.images])

    return (
        <div
            ref={containerRef}
            style={{ flex: "1 1 auto", position: "relative", overflow: "hidden" }}
        >
            <Flex
                ref={scrollRef}
                backgroundColor="gray100"
                borderRadius="md"
                padding={{ top: 40, bottom: props.images.length > 1 ? 64 : 40 }}
                style={{
                    overflowX: "scroll",
                    scrollSnapType: "x mandatory",
                    scrollbarWidth: "none",
                    height: "100%",
                    width: "100%",
                }}
                css={css`
                    &::-webkit-scrollbar {
                        display: none;
                    }
                `}
            >
                <MotionConfig transition={{ duration: 0.2 }}>
                    <AnimatePresence mode="popLayout" initial={false}>
                        {props.images.map((image, index) => (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                key={ImageToUrl(image)}
                                style={{
                                    display: "flex",
                                    flex: "1 0 100%",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    scrollSnapAlign: "center",
                                }}
                            >
                                <div
                                    ref={(el) => {
                                        if (el) {
                                            imageRefsMap.current.set(image.valueOf(), el)
                                        } else {
                                            imageRefsMap.current.delete(image.valueOf())
                                        }
                                    }}
                                    style={{
                                        display: "flex",
                                        height: "100%",
                                        width: "100%",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        maxHeight: scaleValue(420),
                                        overflow: "hidden",
                                    }}
                                >
                                    <ResponsiveImage
                                        style={{
                                            width: "auto",
                                            height: "auto",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                        }}
                                        image={image}
                                        width={304}
                                    />
                                </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </MotionConfig>
                {props.images.length > 1 && (
                    <Flex
                        style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                        padding={12}
                        alignItems="center"
                        justifyContent="center"
                        gap={4}
                        css={css(
                            { "--current-indicator-width": "16px" },
                            responsiveCss("min", "md", { "--current-indicator-width": "48px" })
                        )}
                    >
                        <AnimatePresence initial={false}>
                            {props.images.map((image, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ width: 0, height: 0 }}
                                    animate={{
                                        height: 8,
                                        width:
                                            index === currentIndex
                                                ? "var(--current-indicator-width)"
                                                : 8,
                                        backgroundColor:
                                            index === currentIndex
                                                ? colors.gray500
                                                : `${colors.gray500}66`,
                                    }}
                                    exit={{ opacity: 0, width: 0, height: 0 }}
                                    css={css({
                                        height: 8,
                                        borderRadius: 4,
                                        overflow: "hidden",
                                    })}
                                ></motion.div>
                            ))}
                        </AnimatePresence>
                    </Flex>
                )}
            </Flex>
        </div>
    )
}

Component(ProductCarousel, {
    name: "ProductCarousel",
    gallery: {
        path: "Carousel/Product",
        items: [
            {
                variants: [
                    {
                        props: {
                            images: [
                                `${server()}/static/redoit/product-card-product-image3.png` as any as Image,
                                `${server()}/static/redoit/product-card-product-image4.png` as any as Image,
                            ],
                        },
                        render: (cmp) => <div style={{ width: 600 }}>{cmp}</div>,
                    },
                ],
            },
        ],
    },
})
