import { ComponentProps, useCallback, useContext, createContext, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from "dayjs"
import { EditableSections } from "../../../packages/editing/EditableSections"
import { GetWebPageBreadcrumbDto, useDefaultLocale, useWebPage } from "../../../studio/client"
import { LocaleKey } from "../../localization/Locale"
import { CurrentLocaleContext } from "../../localization/client-side/CurrentLocaleContext"
import { Uuid } from "../../../reactor"
import { WebError } from "./WebError"
import { useLocalize } from "../../localization/client-side/useLocalize"

export type WebPageProps = {
    errorScreen?: typeof WebError

    /**
     * The locale the page should be displayed in.
     */
    locale?: LocaleKey

    /**
     * Props to forward to each EditableSection.
     */
    EditableSectionsProps?: Partial<Omit<ComponentProps<typeof EditableSections>, "sections">>
}

export function WebPage(props: WebPageProps) {
    const navigate = useNavigate()
    const params = useParams()
    const localize = useLocalize()
    const currentLocaleContext = useContext(CurrentLocaleContext)
    const defaultLocale = useDefaultLocale().data?.defaultLocale
    const page = useWebPage(props.locale ?? currentLocaleContext.locale, params.slug)
    const { data, error } = page

    const locale = useMemo(
        () => props.locale ?? currentLocaleContext.locale,
        [currentLocaleContext.locale, props.locale]
    )

    const isNavigating = useMemo(
        () => params.slug !== localize(page.data?.page.slug ?? {}),
        [localize, page.data?.page.slug, params.slug]
    )

    const setLocale = useCallback(
        (l: LocaleKey) => {
            if (currentLocaleContext.setLocale) {
                currentLocaleContext.setLocale(l)
            }
            if (!data) return
            // Redirect to new locale specific path.
            const newPath =
                l === defaultLocale
                    ? `/${data.page.slug?.[defaultLocale.toString()] ?? ""}`
                    : `/${l}/${data.page.slug?.[l.toString()] ?? ""}`
            navigate(newPath)
        },
        [currentLocaleContext, data, defaultLocale, navigate]
    )

    if (data?.page.redirect && typeof window !== "undefined") {
        window.location.href = data.page.redirect.valueOf()
    }

    useEffect(() => {
        const dayjsLocale = locale === LocaleKey("no") ? "nb" : locale.valueOf()
        const getDayjsLocale: Record<string, () => Promise<any>> = {
            nb: () => import("dayjs/locale/nb"),
            en: () => import("dayjs/locale/en"),
            sv: () => import("dayjs/locale/sv"),
        }

        async function updateDayjsLocale() {
            if (typeof getDayjsLocale[dayjsLocale] === "function") {
                await getDayjsLocale[dayjsLocale]()
                dayjs.locale(dayjsLocale)
            }
        }

        void updateDayjsLocale()
    }, [locale])

    if (error) {
        const ErrorScreen = props.errorScreen ?? WebError
        return <ErrorScreen error={error} />
    }

    if (!data || data.page.redirect) return <></>

    return (
        <CurrentLocaleContext.Provider
            value={{
                get locale() {
                    // In context of a page, always return the locale passed to the WebPage, which
                    // should be determined by the URL.
                    return locale
                },
                setLocale,
            }}
        >
            <meta lang={props.locale?.valueOf() ?? currentLocaleContext.locale.valueOf()} />
            <WebPageContext.Provider
                value={{ id: data.page.id, breadcrumb: data.breadcrumb, isNavigating }}
            >
                <EditableSections
                    sections={data.page.sections}
                    {...(props.EditableSectionsProps || {})}
                />
            </WebPageContext.Provider>
        </CurrentLocaleContext.Provider>
    )
}

export const WebPageContext = createContext<{
    id: Uuid<"WebPage"> | undefined

    /**
     * The breadcrumb to the current page.
     */
    breadcrumb: GetWebPageBreadcrumbDto[]

    /**
     * While React Router is doing client side navigation, we're in a state where the URL may be
     * updated to the new location, but components for the previous page is still unmounting and
     * running effects for example, which is useful to know about in some cases.
     */
    isNavigating?: boolean
}>({ id: undefined, breadcrumb: [], isNavigating: undefined })
