import { CSSProperties, ReactNode } from "react"
import { SerializedStyles } from "@emotion/serialize"
import { css, underline } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { Color, colors } from "../../constants/colors"
import { BodySize, bodySizes } from "../../constants/sizes"
import {
    FixedVariant,
    fixedVariantCss,
    resetStyles,
    ResponsiveVariant,
    responsiveVariantsCss,
} from "../../helpers/css"
import { Spacing, spacingToCss } from "../base/Box"

export function Body(props: {
    color?: Color

    size: ResponsiveVariant<BodySize> | FixedVariant<BodySize>

    /**
     * Specify margin.
     */
    margin?: Spacing

    /**
     * Add underline.
     */
    underline?: boolean

    /**
     * Any custom styling.
     *
     * @reflection any
     */
    style?: CSSProperties

    /**
     * Truncate overflowing text. Forces nowrap and adds ellipsis.
     */
    truncate?: boolean

    /**
     * @reflection any
     */
    children: ReactNode
}) {
    return (
        <p
            css={css(
                resetStyles("p"),
                { "& a": underline },
                { fontFamily: "Catalogue" },
                responsiveBodyCss(props.size),
                props.color && {
                    color: colors[props.color],
                },
                props.truncate && {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                },
                props.margin && spacingToCss("margin", props.margin),
                props.underline && underline
            )}
            style={{
                ...(props.style || {}),
            }}
        >
            {props.children}
        </p>
    )
}

export function responsiveBodyCss(
    size: ResponsiveVariant<BodySize> | FixedVariant<BodySize>
): SerializedStyles | undefined {
    if (size instanceof Array && size[0] === "fixed") {
        return fixedVariantCss(bodySizes, size)
    }
    return responsiveVariantsCss(bodySizes, size)
}

Component(Body, {
    name: "Body",
    gallery: {
        path: "Typhography/Body",
        items: [
            { variants: [{ props: { size: "md", children: "Hello, world!" } }] },
            {
                title: "Fixed size",
                variants: [
                    { props: { size: ["fixed", "md", "md"] as any, children: "Hello, world!" } },
                ],
            },
            {
                title: "Media Query Responsive Sizes",
                variants: [
                    { props: { size: ["lg", ["min", "md", "xs"]], children: "Hello, world!" } },
                ],
            },
            {
                variants: [
                    {
                        props: {
                            size: "lg",
                            children:
                                "Our mission is to accelerate the transition to a circular economy. Consumers and retailers will only make that transition if it is cost effective and simple. That's where we come in. Our technology platform enables the lease-and-reuse of consumer goods, which makes them more sustainable, accessible and affordable.",
                        },
                    },
                ],
            },
        ],
    },
})
