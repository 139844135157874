import { ReactNode } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { Icon } from "../visual/Icon"
import { colors } from "../../constants/colors"
import { Text } from "../typography/Text"
import { Spacing } from "../base/Box"

export function WarningMessage(props: {
    margin?: Spacing

    /**
     * @reflection any
     */
    children: ReactNode
}) {
    return (
        <div
            style={{
                ...(typeof props.margin === "undefined" ? { marginTop: 12, marginBottom: 16 } : {}),
                color: colors.warning,
                display: "flex",
            }}
        >
            <Icon icon="warning" color="warning" margin={{ right: 8 }} />
            <Text variant="body" size="md">
                {props.children}
            </Text>
        </div>
    )
}

Component(WarningMessage, { name: "WarningMessage" })
