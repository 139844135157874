import { prettyCamel } from "../../../reactor"
import { ProblemJson } from "../../ssr/ProblemJson"
import { SSRStatus } from "../../ssr/SSRStatus"

export function WebError({ error }: { error: ProblemJson }) {
    SSRStatus.code = error.status ?? 500

    const emoji = {
        401: "🫥",
        403: "🚫",
        404: "🤔",
        500: "🤕",
    }[error.status ?? 500]

    return (
        <div style={{ padding: 64, height: "100%", width: "100%", position: "relative" }}>
            <img
                src="/static/fuse.png"
                style={{ position: "absolute", width: 94, height: 42, right: 64, bottom: 64 }}
            />

            <h1 style={{ color: "#555" }}>
                {emoji} {error.status} {prettyCamel(error.type)}
            </h1>
            <p>{error.detail}</p>
        </div>
    )
}
