import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { Flex } from "../ui/components/base/Flex"
import { colors } from "../ui/constants/colors"
import { responsiveCss, scaleValue } from "../ui/helpers/css"
import { Text } from "../ui/components/typography/Text"
import { Markdown, Uuid } from "../../../../reactor"
import { ContentSection, contentSectionCss } from "./Content"
import { MarkdownView } from "../ui/components/base/MarkdownView"

interface ListWithStepsContentProps extends ContentSection {
    /**
     * @expand
     */
    items: {
        readonly id: Uuid

        /**
         * @title
         */
        text: Localized<Markdown>
    }[]
}

Section("ListWithStepsContent", ListWithStepsContent)
/**
 * @icon ui-dotpoints-01
 */
function ListWithStepsContent(section: ListWithStepsContentProps) {
    const localize = useLocalize()

    return (
        <Flex direction="column" gap={[8, ["min", "md", 12]]} css={css(contentSectionCss(section))}>
            {section.items.map((item, index) => (
                <Flex
                    key={item.id.valueOf()}
                    gap={24}
                    backgroundColor="gray100"
                    padding="md"
                    style={{ borderRadius: 16 }}
                    alignItems="center"
                >
                    <Flex
                        backgroundColor="gray500"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            flexShrink: 0,
                            color: colors.grayWhite,
                            borderRadius: 100,
                        }}
                        css={css(
                            { width: 24, height: 24 },
                            responsiveCss("min", "md", {
                                height: scaleValue(48),
                                width: scaleValue(48),
                            })
                        )}
                    >
                        <Text variant="heading" level={["5", ["min", "md", "4"]]}>
                            {index + 1}
                        </Text>
                    </Flex>
                    <Text variant="body" size="md">
                        <MarkdownView
                            value={localize(item.text)}
                            renderers={{
                                paragraph: (p) => <div>{p.children}</div>,
                            }}
                        />
                    </Text>
                </Flex>
            ))}
        </Flex>
    )
}
