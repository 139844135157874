import { Section } from "../../../../packages/editing/Section"
import {
    cancelSubscription,
    GetPhoneOrderDto,
    postRenewal,
    useCancellationTerms,
    useInsuranceOptions,
    usePhoneOrder,
    useRenewalOptions,
} from "../client"
import { Heading } from "../ui/components/typography/Heading"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { Button } from "../ui/components/buttons/Button"
import { useSearchParams } from "react-router-dom"
import dayjs from "dayjs"
import { Fragment, useCallback, useMemo, useState } from "react"
import { Flex } from "../ui/components/base/Flex"
import { responsiveCss, scaleValue } from "../ui/helpers/css"
import { css } from "@emotion/react"
import { Text } from "../ui/components/typography/Text"
import { Modal } from "../ui/components/modal/Modal"
import { MarkdownView } from "../ui/components/base/MarkdownView"
import { Icon, IconName } from "../ui/components/visual/Icon"
import { Divider } from "../ui/components/other/Divider"
import { CheckoutStepSummaryBlock } from "../ui/components/blocks/CheckoutStepSummaryBlock"
import { PaymentSummary } from "../ui/components/forms/PaymentSummary"
import { Markdown, Uuid } from "../../../../reactor"
import { localizedCurrencyCodes, monthsShort, useFormatAmount } from "../ui/constants/text"
import { Localized } from "../../../../packages/localization/Localized"
import { MustacheString } from "../../../../packages/editing/Mustache"
import { PhoneOrderStatus } from "../../model/PhoneOrder"

type SubscriptionDetailsProps = {
    /**
     * @default '{"no": "Detaljer", "en": "Details"}'
     */
    heading: Localized<string>

    /**
     * @default  '{"no": "Dette abonnementet er kansellert.", "en": "This subscription has been cancelled."}'
     */
    subscriptionIsCancelled: Localized<Markdown>

    status: {
        /**
         * @default '{"no": "Status"}'
         */
        heading: Localized<string>

        /**
         * @default more
         */
        icon: IconName

        /**
         * @default '{"new": {"no": "Ordre lagt"}, "shipped": {"no": "Mobilen er på vei til deg"}}'
         */
        statusLabels: Record<PhoneOrderStatus, Localized<string> | undefined>
    }

    payment: {
        /**
         * @default '{"no": "Månedlig", "en": "Monthly"}'
         */
        monthlyLabel: Localized<string>

        /**
         * @default '{"no": "Pris for telefon."}'
         */
        phonePriceText: Localized<string>

        /**
         * @default '{"no": "Pris for telefon etter innbytterabatt."}'
         */
        phonePriceWithTradeInText: Localized<string>
    }

    subscriptionPeriod: {
        /**
         * Available variables are `{{nextChargeDate}}.`
         * @default '{"no": "Neste forfall", "en": "Next charge date"}'
         */
        heading: Localized<string>

        /**
         * The big text in this component. Available variables are `{{amount}}`.
         * @default '{"no": "Beløp: {{amount}}"}'
         */
        subtitle?: Localized<string>

        /**
         * @default '{"no": "{{monthsLeft}} av {{rentalPeriodMonths}} måneder gjenstår av leieperioden."}'
         */
        text: Localized<string>

        /**
         * Label to show in the top right corner of this component.
         * @default '{"no": "Faktura"}'
         */
        label?: Localized<string>
    }

    insurance: {
        /**
         * @default '{"no": "Trygghetspakker"}'
         */
        heading: Localized<string>

        /**
         * @default '{"no": "Vis betingelser", "en": "Show terms and conditions"}'
         */
        showTermsLink: Localized<string>

        /**
         * Title for modal showing terms and conditions. Available variable is `{{insuranceName}}`
         * which is the display name for the insurance.
         * @default '{"no": "Betingelser for {{insuranceName}}", "en": "Terms and conditions for {{insuranceName}}"}'
         */
        termsModalTitle: Localized<string>

        /**
         * Label to show in the top right corner of this component.
         * @default '{"no": "Månedlig"}'
         */
        label?: Localized<string>
    }

    receipts: {
        /**
         * @default '{"no": "Kvitteringer", "en": "Receipts"}'
         */
        heading: Localized<string>

        /**
         * @default '{"no": "Last ned", "en": "Download"}'
         */
        downloadLinkText: Localized<string>

        /**
         * See https://day.js.org/docs/en/display/format for more details.
         *
         * @default '{"no": "DD.MM.YYYY"}'
         */
        dateFormat: Localized<string>

        /**
         * @default '{"no": "Ingen kvitteringer vil være tilgjengelig før telefonen er sendt og kortet er belastet."}'
         */
        noReceiptsMessage: Localized<string>
    }
}

type SubscriptionCancellationProps = {
    /**
     * @default '{"no": "Kanseller abonnement"}'
     */
    title: Localized<string>

    /**
     * Short text for cancellation prompt. Available variables are `{{monthsRemaining}}` and `{{cancellationFee}}`.
     * @default '{"no": "Er du sikker på at du vil kansellere abonnementet ditt? Du har igjen {{monthsRemaining}} måneder av abonnementet, og du vil måtte betale et bruddgebyr på {{cancellationFee}}."}'
     */
    cancellationPrompt: Localized<string>

    /**
     * @default '{"no": "Bekreft kansellering"}'
     */
    confirmButtonText: Localized<string>

    /**
     * @default '{"no": "Nei, jeg ønsker å videreføre abonnementet"}'
     */
    cancelButtonText: Localized<string>

    /**
     * @default '{"no": "Kanselleringen av abonnementet er sendt til oss. Vi vil behandle den så snart som mulig og komme tilbake til deg."}'
     */
    cancellationSuccessText: Localized<Markdown>

    /**
     * @default '{"no": "Det skjedde en feil ved kanselleringen av ditt abonnement. Prøv igjen, eller ta kontakt med kundeservice så skal vi hjelpe deg."}'
     */
    cancellationErrorText: Localized<Markdown>

    /**
     * @default '{"no": "Lukk", "en": "Close"}'
     */
    closeButtonText: Localized<string>
}

Section(SubscriptionOverview)
function SubscriptionOverview(section: {
    menu: {
        /**
         * @default '{"no": "Forny abonnementet", "en": "Renew subscription"}'
         */
        renewSubscription: Localized<string>

        /**
         * @default '{"no": "Kanseller abonnementet", "en": "Cancel subscription"}'
         */
        cancelSubscription: Localized<string>
    }

    /**
     * @default '{"no": "Ordrenummer", "en": "Order number"}'
     */
    orderNumberLabel: Localized<string>

    /**
     * Title for the modal showing model details if chevron down button is clicked.
     * @default '{"no": "Produktdetaljer", "en": "Product details"}'
     */
    deviceDetailsModalTitle: Localized<string>
    details: SubscriptionDetailsProps
    cancelSubscription: SubscriptionCancellationProps
}) {
    const [params] = useSearchParams()
    const orderId = params.get("id")
    const order = usePhoneOrder((orderId as any) ?? null)
    const localize = useLocalize()
    const [renewalModalIsOpen, setRenewalModalIsOpen] = useState(false)
    const renewalOptions = useRenewalOptions(
        (orderId as any as Uuid<"PhoneOrder"> | undefined) ?? null
    )
    const renewalAvailable = !!renewalOptions.data?.options
    const [cancellationModalIsOpen, setCancellationModalIsOpen] = useState(false)

    if (!order.data) return <></>
    const { model } = order.data

    return (
        <>
            <Flex
                justifyContent="center"
                css={css(
                    { paddingTop: 32, paddingBottom: 32 },
                    responsiveCss("min", "md", { paddingTop: 64, paddingBottom: 64 })
                )}
            >
                <div
                    style={{ maxWidth: 1020, width: "100%" }}
                    css={css(
                        {
                            display: "grid",
                            gridTemplateAreas: '"text details" "menu details"',
                            gridTemplateColumns: "56fr 70fr",
                            gridTemplateRows: "minmax(0, auto)",
                            columnGap: scaleValue(140),
                        },
                        responsiveCss("max", "sm", {
                            gridTemplateAreas: '"text" "details" "menu"',
                            gridTemplateRows: "auto",
                            gridTemplateColumns: "1fr",
                            rowGap: 32,
                        })
                    )}
                >
                    <div
                        css={responsiveCss("min", "md", {
                            marginBottom: 16,
                        })}
                    >
                        <Flex alignItems="center">
                            <Heading level={1} truncate>
                                {localize(model.name)}
                            </Heading>
                        </Flex>
                        {model.features && model.features.length > 0 && (
                            <Flex
                                gap={8}
                                alignItems="center"
                                style={{
                                    overflow: "hidden",
                                }}
                            >
                                <Text
                                    variant="body"
                                    size="xl"
                                    color="gray300"
                                    css={css({
                                        display: "inline-block",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        flex: "1 1 auto",
                                    })}
                                >
                                    {localize(section.orderNumberLabel)}: {order.data.orderNumber}
                                </Text>
                            </Flex>
                        )}
                    </div>
                    <Flex
                        gap={28}
                        direction="column"
                        margin={{ top: scaleValue(32) }}
                        style={{ gridArea: "menu", alignSelf: "start" }}
                    >
                        <Flex
                            style={{ cursor: renewalAvailable ? "pointer" : "inherit" }}
                            justifyContent="space-between"
                            color={!renewalAvailable ? "gray300" : undefined}
                            alignItems="center"
                            onClick={() => {
                                if (!renewalOptions.data?.options) return
                                setRenewalModalIsOpen(true)
                            }}
                        >
                            <Text variant="heading" level="3">
                                {localize(section.menu.renewSubscription)}
                            </Text>
                            <Icon icon="arrowRight" />
                            <Modal
                                isOpen={renewalModalIsOpen}
                                header={{
                                    title: localize({
                                        en: "Renew subscription",
                                        no: "Forny abonnementet",
                                    }),
                                    closeButton: true,
                                }}
                                onClose={() => setRenewalModalIsOpen(false)}
                            >
                                <Text variant="body" size="sm">
                                    <OrderRenewalOptions order={order.data} />
                                </Text>
                            </Modal>
                        </Flex>
                        <Divider horizontal spacing={0} />
                        <Flex
                            color={order.data.cancelled ? "gray300" : "warning"}
                            style={{ cursor: order.data.cancelled ? "inherit" : "pointer" }}
                            justifyContent="space-between"
                            alignItems="center"
                            onClick={
                                order.data.cancelled
                                    ? undefined
                                    : () => setCancellationModalIsOpen(true)
                            }
                        >
                            <Text variant="heading" level="3">
                                {localize(section.menu.cancelSubscription)}
                            </Text>
                            <Icon icon="arrowRight" />
                        </Flex>
                    </Flex>
                    <Flex
                        direction="column"
                        flex="566 0 0"
                        gap={24}
                        style={{ gridArea: "details", alignSelf: "stretch" }}
                    >
                        <Heading level={3}>{localize(section.details.heading)}</Heading>
                        {order.data.cancelled ? (
                            <Text variant="body" size="md">
                                <MarkdownView value={section.details.subscriptionIsCancelled} />
                            </Text>
                        ) : (
                            <OrderSummary order={order.data} {...section.details} />
                        )}
                        {orderId && (
                            <OrderReceipts order={order.data} {...section.details.receipts} />
                        )}
                    </Flex>
                    <CancelSubscription
                        {...section.cancelSubscription}
                        modalIsOpen={cancellationModalIsOpen}
                        setModalIsOpen={setCancellationModalIsOpen}
                        order={order.data}
                    />
                </div>
            </Flex>
        </>
    )
}

function CancelSubscription({
    order,
    modalIsOpen,
    setModalIsOpen,
    ...props
}: {
    order: GetPhoneOrderDto
    modalIsOpen: boolean
    setModalIsOpen: (isOpen: boolean) => void
} & SubscriptionCancellationProps) {
    const localize = useLocalize()
    const cancellationTerms = useCancellationTerms(order.id)
    const formatAmount = useFormatAmount()
    const [state, setState] = useState<"prompt" | "success" | "error">("prompt")

    const handleCancellationConfirmation = useCallback(async () => {
        if (!cancellationTerms.data) {
            setState("error")
            return
        }

        try {
            await cancelSubscription(order.id, {
                cancellationFee: cancellationTerms.data.cancellationFee,
                cancellationTerms: cancellationTerms.data.cancellationTerms,
            })
            setState("success")
        } catch (err) {
            setState("error")
        }
    }, [cancellationTerms.data, order.id])

    if (!cancellationTerms.data) return <></>

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            header={{ title: localize(props.title), closeButton: true }}
        >
            <Flex direction="column" gap={24}>
                {state === "prompt" ? (
                    <>
                        <Text variant="body" size="md">
                            {MustacheString(localize(props.cancellationPrompt), {
                                cancellationFee: formatAmount(
                                    cancellationTerms.data.cancellationFee
                                ),
                                monthsRemaining: cancellationTerms.data.monthsRemaining.toString(),
                            })}
                        </Text>
                        <Text variant="body" size="md">
                            <MarkdownView value={cancellationTerms.data.cancellationTerms} />
                        </Text>
                        <Flex gap={8}>
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => setModalIsOpen(false)}
                            >
                                {localize(props.cancelButtonText)}
                            </Button>
                            <Button
                                variant="dark"
                                size="sm"
                                onClick={handleCancellationConfirmation}
                            >
                                {localize(props.confirmButtonText)}
                            </Button>
                        </Flex>
                    </>
                ) : state === "error" ? (
                    <>
                        <Text variant="body" size="md">
                            <MarkdownView value={props.cancellationErrorText} />
                        </Text>
                        <Button variant="secondary" size="sm" onClick={() => setModalIsOpen(false)}>
                            {localize(props.closeButtonText)}
                        </Button>
                    </>
                ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                state === "success" ? (
                    <>
                        <Text variant="body" size="md">
                            <MarkdownView value={props.cancellationSuccessText} />
                        </Text>
                        <Button variant="secondary" size="sm" onClick={() => setModalIsOpen(false)}>
                            {localize(props.closeButtonText)}
                        </Button>
                    </>
                ) : null}
            </Flex>
        </Modal>
    )
}

function OrderSummary({ order, ...props }: { order: GetPhoneOrderDto } & SubscriptionDetailsProps) {
    const price = order.rentalPeriod.monthlyPrice
    const localize = useLocalize()
    const formatAmount = useFormatAmount()
    const insuranceOptions = useInsuranceOptions(
        order.model.id,
        order.phone.storage,
        order.phone.color,
        order.rentalPeriod.months
    )
    const [showInsuranceTerms, setShowInsuranceTerms] = useState(false)
    const insurance = insuranceOptions.data?.find((io) => io.id === order.insurance)

    const statusToDisplay: Localized<string> | undefined = useMemo(() => {
        if (order.customStatus) return order.customStatus
        if (order.status && order.status in props.status.statusLabels) {
            return props.status.statusLabels[order.status]
        }
    }, [order.customStatus, order.status, props.status.statusLabels])

    dayjs.extend(advancedFormat)

    return (
        <Flex direction="column" gap={24}>
            {statusToDisplay ? (
                <CheckoutStepSummaryBlock
                    step={localize(props.status.heading)}
                    icon={props.status.icon}
                    title={localize(statusToDisplay)}
                />
            ) : null}
            <PaymentSummary
                originalPrice={price.phone.majorUnits}
                discountedPrice={
                    price.tradeInDiscount
                        ? price.phone.majorUnits - price.tradeInDiscount.majorUnits
                        : price.phone.majorUnits
                }
                currency={localize(
                    localizedCurrencyCodes[price.total.currency] ?? { no: price.total.currency }
                )}
                label={localize(props.payment.monthlyLabel)}
                itemName={localize(order.model.name)}
                rentalPeriodUnit={localize(monthsShort)}
                text={
                    price.tradeInDiscount
                        ? localize(props.payment.phonePriceWithTradeInText)
                        : localize(props.payment.phonePriceText)
                }
            />
            <CheckoutStepSummaryBlock
                step={MustacheString(localize(props.subscriptionPeriod.heading), {
                    nextChargeDate: dayjs(order.nextChargeDate).format("DD.MM.YYYY"),
                })}
                icon="calendar"
                title={
                    props.subscriptionPeriod.subtitle
                        ? MustacheString(localize(props.subscriptionPeriod.subtitle), {
                              amount: formatAmount(price.total),
                          })
                        : undefined
                }
                text={MustacheString(localize(props.subscriptionPeriod.text), {
                    monthsLeft: order.monthsLeft.toString(),
                    rentalPeriodMonths: order.rentalPeriod.months.toString(),
                })}
                label={
                    props.subscriptionPeriod.label
                        ? localize(props.subscriptionPeriod.label)
                        : undefined
                }
            />
            {insurance ? (
                <>
                    <CheckoutStepSummaryBlock
                        step={localize(props.insurance.heading)}
                        icon="shield"
                        title={localize(insurance.displayName)}
                        price={price.insurance ? formatAmount(price.insurance) : undefined}
                        list={insurance.sellingPoints.map((sp) => ({
                            id: sp.id.valueOf(),
                            icon: sp.icon,
                            text: localize(sp.text),
                        }))}
                        text={
                            <Text
                                onClick={() => setShowInsuranceTerms(true)}
                                underline
                                style={{ cursor: "pointer" }}
                            >
                                {localize(props.insurance.showTermsLink)}
                            </Text>
                        }
                        label={props.insurance.label ? localize(props.insurance.label) : undefined}
                    />
                    <Modal
                        isOpen={showInsuranceTerms}
                        header={{
                            title: MustacheString(localize(props.insurance.termsModalTitle), {
                                insuranceName: localize(insurance.displayName),
                            }),
                            closeButton: true,
                        }}
                        onClose={() => setShowInsuranceTerms(false)}
                    >
                        <Text variant="body" size="sm">
                            <MarkdownView value={localize(insurance.termsAndConditions.content)} />
                        </Text>
                    </Modal>
                </>
            ) : null}
        </Flex>
    )
}

function OrderReceipts({
    order,
    ...props
}: { order: GetPhoneOrderDto } & SubscriptionDetailsProps["receipts"]) {
    const localize = useLocalize()
    const formatAmount = useFormatAmount()

    dayjs.extend(advancedFormat)

    return (
        <Flex borderRadius="md" padding="md" borderColor="gray200" direction="column" gap={16}>
            <Flex justifyContent="space-between" alignItems="center">
                <Flex gap={8}>
                    <Icon icon="receipt" /> <Heading level={4}>{localize(props.heading)}</Heading>
                </Flex>
            </Flex>
            <Flex gap={24} direction="column">
                {order.receipts.length ? (
                    order.receipts.map((receipt, index) => (
                        <Fragment key={receipt.id.valueOf()}>
                            {index > 0 && <Divider horizontal spacing={0} />}
                            <a
                                href={`/api/phone-orders/${order.id}/pdf-receipts/${receipt.id}`}
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Text variant="body" size="md">
                                    {dayjs(receipt.date.valueOf()).format(
                                        localize(props.dateFormat)
                                    )}
                                </Text>
                                <Flex gap={24}>
                                    <Text variant="body" size="md">
                                        {formatAmount(receipt.total)}
                                    </Text>
                                    <Text variant="body" size="md" underline>
                                        {localize(props.downloadLinkText)}
                                    </Text>
                                </Flex>
                            </a>
                        </Fragment>
                    ))
                ) : (
                    <Text variant="body" size="sm" color="gray300">
                        {localize(props.noReceiptsMessage)}
                    </Text>
                )}
            </Flex>
        </Flex>
    )
}

function OrderRenewalOptions({ order }: { order: GetPhoneOrderDto }) {
    const renewalOptions = useRenewalOptions(order.id)

    return (
        <>
            <Heading level={3} margin={{ top: 32, bottom: 16 }}>
                Renew your subscription
            </Heading>
            {renewalOptions.data?.options.length === 0 && <div>No renewal options available</div>}
            {renewalOptions.error && <div>{renewalOptions.error.detail}</div>}
            {renewalOptions.data?.options.map((option) => (
                <div
                    style={{
                        margin: 16,
                        border: "1px solid #ddd",
                        padding: 16,
                        borderRadius: 8,
                        cursor: "pointer",
                    }}
                    onClick={async () => {
                        if (!confirm("Are you sure you want to renew your subscription?")) return
                        try {
                            await postRenewal(order.id, option.months)
                            alert("Renewal successful")
                        } catch (e: any) {
                            alert("Renewal failed: " + e.detail)
                        }
                    }}
                >
                    <div>{option.months} months</div>
                    <div>{option.price.total.majorUnits.toFixed(2)} NOK / mo</div>
                </div>
            ))}
        </>
    )
}
