import { CSSProperties } from "react"

export function Flag(props: { flag: "en" | "no"; style?: CSSProperties }) {
    switch (props.flag) {
        case "en":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={16}
                    fill="none"
                    style={props.style}
                >
                    <g clipPath="url(#a)">
                        <rect width={21} height={15} x={0.5} y={0.5} fill="#1A47B8" rx={3} />
                        <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M2.734.5H.5V3l18.754 12.5H21.5V13L2.734.5Z"
                            clipRule="evenodd"
                        />
                        <path fill="#F93939" d="M1.245.5 21.5 14.035V15.5h-.728L.5 1.95V.5h.745Z" />
                        <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M19.5.5h2V3s-12.99 8.328-19 12.5h-2V13L19.5.5Z"
                            clipRule="evenodd"
                        />
                        <path fill="#F93939" d="M21.5.5h-.678L.5 14.047V15.5h.745L21.5 1.962V.5Z" />
                        <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M8.137.5h5.743v4.627h7.62v5.743h-7.62v4.63H8.137v-4.63H.5V5.127h7.637V.5Z"
                            clipRule="evenodd"
                        />
                        <path
                            fill="#F93939"
                            fillRule="evenodd"
                            d="M9.342.5h3.316v5.77H21.5v3.46h-8.842v5.77H9.342V9.73H.5V6.27h8.842V.5Z"
                            clipRule="evenodd"
                        />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <rect width={21} height={15} x={0.5} y={0.5} fill="#fff" rx={3} />
                        </clipPath>
                    </defs>
                </svg>
            )
        case "no":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 16"
                    width={22}
                    height={16}
                    fill="none"
                    style={props.style}
                >
                    <g clipPath="url(#a)">
                        <rect width={21} height={15} x={0.5} y={0.5} fill="#af010d" rx={3} />
                        <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M6.5 9.5h-6v-3h6v-6h3v6h12v3h-12v6h-3v-6Z"
                            clipRule="evenodd"
                            style={{
                                fill: "#fff",
                                fillOpacity: 1,
                            }}
                        />
                        <path
                            fill="#1a47b8"
                            fillRule="evenodd"
                            d="M7.5 8.5h-7v-1h7v-7h1v7h13v1h-13v7h-1v-7Z"
                            clipRule="evenodd"
                        />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <rect width={21} height={15} x={0.5} y={0.5} fill="#fff" rx={3} />
                        </clipPath>
                    </defs>
                </svg>
            )
        default:
            return null
    }
}
