import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { Image } from "../../../../reactor"
import { ResponsiveImage } from "../ui/components/base/ResponsiveImage"
import { BorderRadiusVariant } from "../ui/constants/sizes"
import { css, responsiveBorderRadius } from "../ui/helpers/css"
import { ContentSection, contentSectionCss } from "./Content"

interface ImageContent extends ContentSection {
    image: Image

    /**
     * If not provided, image will be hidden from screen readers.
     */
    altText?: Localized<string>

    /**
     * The width of the image in CSS-pixels. An image with the correct resolution will be
     * automatically requested based on the device pixel density.
     * @default 640
     */
    width: number

    /**
     * Set if the image should have rounded corners.
     */
    borderRadius?: BorderRadiusVariant

    /**
     * Shows the image cropped to given aspect ratio. Provided on the format `width / height`,
     * for example `16 / 9` or `4 / 3`. The input is not validated, so make sure a valid value
     * is entered.
     */
    aspectRatio?: string
}
/**
 * @icon ui-image-01
 */
function ImageContent(section: ImageContent) {
    const localize = useLocalize()
    return (
        <div css={css(contentSectionCss(section))}>
            <ResponsiveImage
                aria-hidden={!section.altText}
                alt={section.altText ? localize(section.altText) : undefined}
                style={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    aspectRatio: section.aspectRatio,
                }}
                image={section.image}
                width={section.width}
                css={
                    section.borderRadius ? responsiveBorderRadius(section.borderRadius) : undefined
                }
            />
        </div>
    )
}

Section("ImageContent", ImageContent)
