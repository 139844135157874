import { useCallback, useState } from "react"
import { Markdown, Uuid } from "../../../../reactor"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { Notification } from "../ui/components/Notification"
import { postNotificationsClosed, useNotificationsClosed } from "../client"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Color } from "../ui/constants/colors"

/**
 * @icon ui-announcement-03
 */
function FullNotification(section: {
    /**
     * Id of the section.

     * @hide
     */
    readonly id: Uuid<any>

    /**
     * The text to present in the notification.
     */
    text: Localized<Markdown>

    /**
     * @default forest
     */
    color: Color

    /**
     * Whether the user is allowed to close the notification
     */
    allowClose?: boolean
}) {
    const notificationsClosed = useNotificationsClosed()
    const [justClosed, setJustClosed] = useState(false)
    const localize = useLocalize()

    const handleClose = useCallback(async () => {
        void postNotificationsClosed({ id: section.id.valueOf() })
        setJustClosed(true)
    }, [section.id])

    return notificationsClosed.data?.includes(section.id.valueOf()) || justClosed ? (
        <></>
    ) : (
        <Notification
            full
            color={section.color}
            onClose={section.allowClose ? handleClose : undefined}
            text={typeof section.text === "string" ? section.text : localize(section.text)}
        />
    )
}

Section(FullNotification)
