import { Fragment, useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import {
    postPhoneOffer,
    useConditionGrades,
    useFrequentlyAskedQuestions,
    useMe,
    usePhoneOffer,
} from "../client"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { useLocalizeSlug } from "../../../../packages/web/hooks/useLocalizeSlug"
import { Heading } from "../ui/components/typography/Heading"
import { Markdown, NonNegativeInteger, Uuid } from "../../../../reactor"
import { useWebPageInfo } from "../../../../studio/client"
import { Button } from "../ui/components/buttons/Button"
import { Page } from "../../model/Page"
import { usePhoneOfferOptions, useResetPhoneOffer } from "./Checkout"
import { Flex } from "../ui/components/base/Flex"
import { ProductCarousel } from "../ui/components/carousel/ProductCarousel"
import { WhatsInTheBoxBlock } from "../ui/components/blocks/WhatsInTheBoxBlock"
import { Image } from "../../../../reactor"
import { Localized } from "../../../../packages/localization/Localized"
import { Box } from "../ui/components/base/Box"
import { LabelNotification } from "../ui/components/notification/LabelNotification"
import { Tooltip } from "../ui/components/base/Tooltip"
import { Text } from "../ui/components/typography/Text"
import { Divider } from "../ui/components/other/Divider"
import { RadioGroup } from "../ui/components/forms/RadioGroup"
import { responsiveCss, responsiveSectionSpacing, scaleValue } from "../ui/helpers/css"
import { MustacheString } from "../../../../packages/editing/Mustache"
import { Notification } from "../ui/components/Notification"
import { IconName } from "../ui/components/visual/Icon"
import { BenefitsBlock } from "../ui/components/blocks/BenefitsBlock"
import { StepsBlock } from "../ui/components/blocks/StepsBlock"
import { FaqBlock } from "../ui/components/blocks/FaqBlock"
import { Modal } from "../ui/components/modal/Modal"
import { MarkdownView } from "../ui/components/base/MarkdownView"
import { Color } from "../ui/constants/colors"
import { monthsLong, monthsShort, useFormatAmount } from "../ui/constants/text"

/**
 * Declares the global `fbq` function for Meta Pixel.
 */
declare const fbq: undefined | ((eventType: string, eventName: string, ...args: any[]) => void)

type SearchParams = "phoneId"
const searchParamsSlugs: { [K in SearchParams]: string } = { phoneId: "phone" }
export function ProductDetailsSearchParams(params: { phoneId: Uuid<"Phone"> }) {
    return Object.entries(params)
        .map(([k, v]) => {
            if (!v) return
            if (k in searchParamsSlugs === false) return
            // Assert k as a key of searchParamsKeys to satisfy TypeScript
            const key = k as keyof typeof searchParamsSlugs
            return `${searchParamsSlugs[key]}=${v.valueOf()}`
        })
        .filter((s) => !!s)
        .join("&")
}

Section(ProductDetails)
function ProductDetails(section: {
    /**
     * Heading for the modal that opens when button next to product name is clicked.
     * @default '{"en": "Product details", "no": "Produktdetaljer"}'
     */
    productDetailsModalHeading: Localized<string>

    configure: {
        /**
         * @default '{"en": "Color: {{selectedColor}}", "no": "Farge: {{selectedColor}}"}'
         */
        colorLabel: Localized<string>

        /**
         * @default '{"en": "Only available in color(s) {{colors}}.", "no": "Kun tilgjengelig i fargene {{colors}}."}'
         */
        limitedColorsAvailabilityTooltip: Localized<string>

        /**
         * @default '{"en": "Only available in storage sizes(s) {{storageSizes}}.", "no": "Kun tilgjengelig med lagringsplass {{storageSizes}}."}'
         */
        limitedStorageSizesAvailabilityTooltip: Localized<string>

        /**
         * @default '{"en": "Out of stock", "no": "Ikke på lager"}'
         */
        outOfStockTooltip: Localized<string>

        /**
         * @default '{"en": "Storage size", "no": "Lagringsplass"}'
         */
        storageSizeLabel: Localized<string>

        /**
         * @default '{"en": "Rental period", "no": "Leieperiode"}'
         */
        rentalPeriodLabel: Localized<string>

        /**
         * @default '{"no": "Totalpris etter {{rentalPeriod}} {{monthsLong}} er {{totalPrice}}"}'
         */
        rentalPeriodTotalPrice: Localized<string>

        /**
         * @default '{"en": "Based on a {{rentalPeriod}}-month subscription", "no": "Basert på en {{rentalPeriod}}-måneders leieperiode"}'
         */
        totalPricePostFix: Localized<string>

        cta: {
            /**
             * @default '{"en": "Rent this phone", "no": "Lei denne telefonen"}'
             */
            buttonText: Localized<string>

            /**
             * The page to open when clicking the CTA.
             */
            page: Page["id"]
        }
    }

    notifications?: {
        readonly id: Uuid
        icon?: IconName
        /**
         * @title
         */
        text: Localized<Markdown>

        /**
         * @default gray100
         */
        color?: Color
    }[]

    whatsInTheBox?: {
        /**
         * @default '{"en": "What’s in the box?", "no": "Hva er i esken?"}'
         */
        heading: Localized<string>
        /**
         * @expand
         */
        items: {
            image: Image
            /**
             * @title
             */
            heading: Localized<string>
            text: Localized<string>
        }[]
    }

    steps?: {
        heading: Localized<string>
        /**
         * @expand
         */
        items: {
            readonly id: Uuid
            /**
             * @title
             */
            text: Localized<Markdown>
        }[]
        image?: Image
    }

    benefits?: {
        heading: Localized<string>
        /**
         * @expand
         */
        items: {
            readonly id: Uuid<"Benefit">
            icon: IconName
            /**
             * @title
             */
            heading: Localized<string>
            text: Localized<Markdown>
        }[]
    }

    frequentlyAskedQuestions?: {
        /**
         * Heading for the FAQ block.
         * @default '{"en": "Frequently asked questions", "no": "Vanlige spørsmål"}'
         */
        heading: Localized<string>

        /**
         * The maximum number of questions initially displayed before clicking "Show all" button.
         * @default 3
         */
        maxInitialQuestions?: NonNegativeInteger

        /**
         * Text for button to show all questions.
         * @default '{"en": "Show all", "no": "Vis alle"}'
         */
        showAllButtonText: Localized<string>
    }

    /**
     * Modal that will be shown to the user when they click the primary action button if they have
     * reached their order limit.
     */
    orderLimitReachedModal: {
        /**
         * @default '{"no": "Maksgrense nådd"}'
         */
        title: Localized<string>

        /**
         * @default '{"no": "Du har nådd maks antall bestillinger hos oss. Ta kontakt med oss dersom du mener dette er feil og ønsker å bestille mer."}'
         */
        text: Localized<Markdown>

        /**
         * @default '{"no": "Lukk"}'
         */
        closeButtonText: Localized<string>
    }
}) {
    const localize = useLocalize()
    const conditionGrades = useConditionGrades()
    const [phoneState, setPhone] = useResetPhoneOffer()
    const [offerOptions, setOfferOptions] = usePhoneOfferOptions()
    const frequentlyAskedQuestions = useFrequentlyAskedQuestions()
    const [searchParams] = useSearchParams()
    const phoneId = searchParams.get(searchParamsSlugs.phoneId) as any as Uuid<"Phone"> | undefined
    const me = useMe()

    // Just pass 0 to get the shortest available rental period.
    const phoneOffer = usePhoneOffer(phoneId ?? null, 0)
    const [descriptionModalIsOpen, setDescriptionModalIsOpen] = useState(false)
    const checkoutPage = useWebPageInfo(section.configure.cta.page)
    const localizeSlug = useLocalizeSlug()
    const [sessionStateReset, setSessionStateReset] = useState(false)
    const formatAmount = useFormatAmount()
    const [showOrderLimitReachedModal, setShowOrderLimitReachedModal] = useState(false)

    const handleOrderLimitReachedCtaClick = useCallback(() => {
        setShowOrderLimitReachedModal(true)
    }, [])

    // Effect to ensure checkout state is reset whenever returning to product details page.
    // Might want to move to the checkout section.
    useEffect(() => {
        if (!sessionStateReset && phoneOffer.data?.offer) {
            setPhone(phoneOffer.data.offer)
            setSessionStateReset(true)
        }
    }, [phoneOffer.data?.offer, sessionStateReset, setPhone])

    useEffect(() => {
        // Keep options session state up do date for use later in checkout flow.
        if (phoneOffer.data?.options) {
            setOfferOptions(phoneOffer.data.options)
        }
    }, [phoneOffer.data?.options, setOfferOptions])

    useEffect(() => {
        // If phoneState is for a different model than currently viewing, replace it.
        if (phoneOffer.data?.offer && phoneState?.model !== phoneOffer.data.offer.model) {
            setPhone(phoneOffer.data.offer)
        }
    }, [phoneOffer.data?.offer, phoneState?.model, setOfferOptions, setPhone])

    // Reset phone state when the current state is for a different model than currently viewed.
    useEffect(() => {
        if (phoneOffer.data && phoneOffer.data.offer.model !== phoneState?.model) {
            setPhone(phoneOffer.data.offer)
            setOfferOptions(phoneOffer.data.options)
        }
    }, [phoneOffer.data, phoneState?.model, setOfferOptions, setPhone])

    // Primarily use the state stored in session storage,
    // fall back to intial data from hook for SSR support.
    const phone = phoneState ?? phoneOffer.data?.offer
    const options = offerOptions ?? phoneOffer.data?.options

    const phoneConditionGrade = conditionGrades.data?.find((cid) => cid.id === phone?.condition)

    if (!phone || !options || !frequentlyAskedQuestions.data) {
        return <></>
    }

    if (!checkoutPage.data?.slug) {
        return <div>Missing checkout page slug, or product details configure CTA page.</div>
    }

    return (
        <Flex
            css={css(
                { flexDirection: "column", gap: 32 },
                responsiveCss("min", "md", { flexDirection: "row" }),
                responsiveCss("min", "lg", { gap: scaleValue(144) }),
                responsiveSectionSpacing()
            )}
        >
            <div style={{ flex: "1 0 0" }}>
                <Flex
                    gap={48}
                    direction="column"
                    alignSelf="flex-start"
                    css={css(
                        responsiveCss("max", "sm", { maxHeight: 372 }),
                        responsiveCss("min", "md", {
                            position: "sticky",
                            top: 24,
                            left: 0,
                            height: "calc(100vh - 24px - 24px)",
                            maxHeight: "calc(100vh - 24px - 24px)",
                        })
                    )}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            aspectRatio: "1 / 1",
                            minHeight: 0,
                        }}
                    >
                        <ProductCarousel images={phone.images ?? []} />
                    </div>
                    {/** Note this is rendered in two places due to different placement and compact on mobile vs. desktop. */}
                    {section.whatsInTheBox ? (
                        <div
                            style={{ flex: "0 0 auto" }}
                            aria-hidden={true}
                            css={responsiveCss("max", "sm", {
                                display: "none",
                            })}
                        >
                            <WhatsInTheBoxBlock
                                heading={localize(section.whatsInTheBox.heading)}
                                items={section.whatsInTheBox.items.map((item) => ({
                                    image: item.image,
                                    heading: localize(item.heading),
                                    text: localize(item.text),
                                }))}
                            />
                        </div>
                    ) : null}
                </Flex>
            </div>
            <Flex flex="1 0 0" direction="column" gap={48}>
                <Flex direction="column" gap={16}>
                    <Box>
                        {!!phoneConditionGrade && (
                            <div style={{ marginBottom: 8 }}>
                                <Tooltip
                                    renderTrigger={(ref) => (
                                        <LabelNotification color="sand" ref={ref}>
                                            {localize(phoneConditionGrade.name)}
                                        </LabelNotification>
                                    )}
                                >
                                    <Text
                                        variant="label"
                                        size="md"
                                        color="sand"
                                        margin={{ bottom: 4 }}
                                    >
                                        {localize(phoneConditionGrade.name)}
                                    </Text>
                                    <Text variant="body" size="sm">
                                        <MarkdownView
                                            value={localize(phoneConditionGrade.description)}
                                        />
                                    </Text>
                                </Tooltip>
                            </div>
                        )}
                        <div>
                            <div
                                css={css(
                                    {
                                        display: "grid",
                                        gridTemplateAreas:
                                            phone.features && phone.features.length > 0
                                                ? '"heading button" "features button"'
                                                : '"heading button"',
                                        gridTemplateColumns: "1fr auto",
                                        gridTemplateRows: "auto auto",
                                        gap: 8,
                                    },
                                    responsiveCss("min", "lg", {
                                        gridTemplateAreas:
                                            phone.features && phone.features.length > 0
                                                ? '"heading heading" "features button"'
                                                : '"heading button"',
                                        gap: "0px 16px",
                                        marginBottom: 16,
                                    })
                                )}
                            >
                                <Flex
                                    style={{ gridArea: "heading", height: "100%" }}
                                    alignItems="center"
                                >
                                    <Heading level={1}>{localize(phone.name)}</Heading>
                                </Flex>
                                {phone.features && phone.features.length > 0 && (
                                    <Flex
                                        gap={8}
                                        alignItems="center"
                                        style={{
                                            gridArea: "features",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Text
                                            variant="body"
                                            size="xl"
                                            color="gray300"
                                            css={css({
                                                display: "inline-block",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                flex: "1 1 auto",
                                            })}
                                        >
                                            {phone.features.map((feature, index) => (
                                                <Fragment key={feature.id.valueOf()}>
                                                    {index > 0 && (
                                                        <Text
                                                            variant="body"
                                                            size={["lg", ["min", "lg", "xl"]]}
                                                            color="gray300"
                                                            css={css({
                                                                display: "inline-block",
                                                                marginLeft: 8,
                                                                marginRight: 8,
                                                            })}
                                                        >
                                                            ·
                                                        </Text>
                                                    )}
                                                    {localize(feature.text)}
                                                </Fragment>
                                            ))}
                                        </Text>
                                    </Flex>
                                )}
                                <Flex
                                    style={{ gridArea: "button", height: "100%" }}
                                    alignItems="center"
                                >
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        iconStart="chevronDown"
                                        onClick={() => setDescriptionModalIsOpen(true)}
                                    />
                                </Flex>
                                {phone.description && (
                                    <Modal
                                        header={{
                                            icon: "device",
                                            title: localize(section.productDetailsModalHeading),
                                            closeButton: true,
                                        }}
                                        isOpen={descriptionModalIsOpen}
                                        onClose={() => setDescriptionModalIsOpen(false)}
                                    >
                                        <Text variant="body" size="sm">
                                            <MarkdownView value={localize(phone.description)} />
                                        </Text>
                                    </Modal>
                                )}
                            </div>
                        </div>
                    </Box>
                    {/** Note this is rendered in two places due to different placement and compact on mobile vs. desktop. */}
                    {section.whatsInTheBox ? (
                        <div
                            aria-hidden={true}
                            css={responsiveCss("min", "md", {
                                display: "none",
                            })}
                        >
                            <WhatsInTheBoxBlock
                                compact={true}
                                heading={localize(section.whatsInTheBox.heading)}
                                items={section.whatsInTheBox.items.map((item) => ({
                                    image: item.image,
                                    heading: localize(item.heading),
                                    text: localize(item.text),
                                }))}
                            />
                        </div>
                    ) : null}

                    <Flex
                        borderRadius="lg"
                        borderColor="gray200"
                        padding="md"
                        direction="column"
                        css={css({ gap: 24 }, responsiveCss("min", "md", { gap: 28 }))}
                    >
                        <div>
                            <Heading level={4} margin={{ bottom: 16 }}>
                                {MustacheString(localize(section.configure.colorLabel), {
                                    selectedColor: localize(
                                        options.colors.find((c) => c.name === phone.color)
                                            ?.displayName ?? {}
                                    ),
                                })}
                            </Heading>
                            <Flex gap={16}>
                                <RadioGroup
                                    options={options.colors.map((color) => ({
                                        value: color.name,
                                        text: localize(color.displayName),
                                        disabled: !color.inStock,
                                    }))}
                                    value={phone.color}
                                    renderOption={(option, selected) => {
                                        const color = options.colors.find(
                                            (c) => c.name === option.value
                                        )
                                        // Show limited availability tooltip selectin this config
                                        // option is not compatible with other selected options, and
                                        // will cause a change in the selected config.
                                        const limitedAvailability =
                                            color &&
                                            color.storageSizes.length &&
                                            options.storageSizes.length > color.storageSizes.length
                                                ? color.storageSizes
                                                      .map((ss) => {
                                                          const ssdn = options.storageSizes.find(
                                                              (oss) => oss.name === ss
                                                          )?.displayName
                                                          if (ssdn)
                                                              return {
                                                                  id: ss,
                                                                  displayName: localize(ssdn),
                                                              }
                                                      })
                                                      .filter((ss) => !!ss)
                                                : undefined

                                        const showLimitedAvailability =
                                            !selected &&
                                            !limitedAvailability?.filter(
                                                (ss) => ss.id === phone.storage
                                            ).length
                                        return (
                                            color && (
                                                <Tooltip
                                                    renderTrigger={(ref) => (
                                                        <Flex
                                                            ref={ref as any}
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            borderWidth={2}
                                                            borderColor={
                                                                selected ? "brand" : undefined
                                                            }
                                                            css={css(
                                                                {
                                                                    borderRadius: 100,
                                                                    height: 38,
                                                                    width: 38,
                                                                },
                                                                responsiveCss("min", "xl", {
                                                                    height: 44,
                                                                    width: 44,
                                                                })
                                                            )}
                                                        >
                                                            <div
                                                                role="radio"
                                                                aria-label={localize(
                                                                    color.displayName
                                                                )}
                                                                style={{
                                                                    backgroundColor: color.color,
                                                                    opacity: option.disabled
                                                                        ? 0.2
                                                                        : 1,
                                                                    cursor: option.disabled
                                                                        ? "not-allowed"
                                                                        : "pointer",
                                                                }}
                                                                css={css(
                                                                    {
                                                                        borderRadius: 100,
                                                                        height: 30,
                                                                        width: 30,
                                                                    },
                                                                    responsiveCss("min", "xl", {
                                                                        height: 36,
                                                                        width: 36,
                                                                    })
                                                                )}
                                                            />
                                                        </Flex>
                                                    )}
                                                >
                                                    {limitedAvailability?.length &&
                                                    showLimitedAvailability ? (
                                                        <Text variant="body" size="sm">
                                                            {MustacheString(
                                                                localize(
                                                                    section.configure
                                                                        .limitedStorageSizesAvailabilityTooltip
                                                                ),
                                                                {
                                                                    storageSizes:
                                                                        limitedAvailability
                                                                            .map(
                                                                                (la) =>
                                                                                    la.displayName
                                                                            )
                                                                            .join(", "),
                                                                }
                                                            )}
                                                        </Text>
                                                    ) : option.disabled ? (
                                                        <Text variant="body" size="sm">
                                                            {localize(
                                                                section.configure.outOfStockTooltip
                                                            )}
                                                        </Text>
                                                    ) : null}
                                                </Tooltip>
                                            )
                                        )
                                    }}
                                    onChange={async (e, val) => {
                                        setPhone(
                                            await postPhoneOffer("color", {
                                                ...phone,
                                                color: val,
                                            })
                                        )
                                    }}
                                />
                            </Flex>
                        </div>
                        <Divider horizontal spacing={0} />
                        <div>
                            <Heading level={4} margin={{ bottom: 16 }}>
                                {localize(section.configure.storageSizeLabel)}
                            </Heading>
                            <div
                                style={{
                                    gap: 8,
                                    display: "flex",
                                }}
                                css={css({ "> *": { flex: "1 0 0" } })}
                            >
                                <RadioGroup
                                    value={phone.storage}
                                    options={options.storageSizes.map((storage) => ({
                                        value: storage.name,
                                        text: localize(storage.displayName),
                                        disabled: !storage.inStock,
                                    }))}
                                    renderOption={(option, selected) => {
                                        const storageSize = options.storageSizes.find(
                                            (ss) => ss.name === option.value
                                        )
                                        // Show limited availability tooltip selectin this config
                                        // option is not compatible with other selected options, and
                                        // will cause a change in the selected config.
                                        const limitedAvailability =
                                            storageSize &&
                                            storageSize.colors.length &&
                                            options.colors.length > storageSize.colors.length
                                                ? storageSize.colors
                                                      .map((c) => {
                                                          const cdn = options.colors.find(
                                                              (colorOption) =>
                                                                  colorOption.name === c
                                                          )?.displayName
                                                          if (cdn)
                                                              return {
                                                                  id: c,
                                                                  displayName: localize(cdn),
                                                              }
                                                      })
                                                      .filter((c) => !!c)
                                                : undefined
                                        const showLimitedAvailability =
                                            !selected &&
                                            !limitedAvailability?.filter(
                                                (c) => c.id === phone.color
                                            ).length
                                        return (
                                            storageSize && (
                                                <Tooltip
                                                    renderTrigger={(ref) => (
                                                        <div ref={ref as any}>
                                                            <Button
                                                                variant="select"
                                                                selected={selected}
                                                                fullwidth
                                                                as="div"
                                                                size="sm"
                                                                disabled={option.disabled}
                                                            >
                                                                {option.text}
                                                            </Button>
                                                        </div>
                                                    )}
                                                >
                                                    {showLimitedAvailability &&
                                                    limitedAvailability?.length ? (
                                                        <Text variant="body" size="sm">
                                                            {MustacheString(
                                                                localize(
                                                                    section.configure
                                                                        .limitedColorsAvailabilityTooltip
                                                                ),
                                                                {
                                                                    colors: limitedAvailability
                                                                        .map((la) => la.displayName)
                                                                        .join(", "),
                                                                }
                                                            )}
                                                        </Text>
                                                    ) : option.disabled ? (
                                                        <Text variant="body" size="sm">
                                                            {localize(
                                                                section.configure.outOfStockTooltip
                                                            )}
                                                        </Text>
                                                    ) : null}
                                                </Tooltip>
                                            )
                                        )
                                    }}
                                    onChange={async (e, val) => {
                                        setPhone(
                                            await postPhoneOffer("storage", {
                                                ...phone,
                                                storage: val,
                                            })
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <Divider horizontal spacing={0} />
                        <div>
                            <Heading level={4} margin={{ bottom: 16 }}>
                                {localize(section.configure.rentalPeriodLabel)}
                            </Heading>
                            <Flex
                                direction="column"
                                gap={8}
                                css={css({ "> *": { flex: "1 0 0" } })}
                            >
                                <RadioGroup
                                    value={phone.rentalPeriod}
                                    options={options.rentalPeriods.map((rp) => ({
                                        value: rp.months,
                                        text: localize(rp.displayName),
                                    }))}
                                    renderOption={(option, selected) => {
                                        const rentalPeriodPrice = phone.monthlyPrices.find(
                                            (rpp) => rpp.rentalPeriod === option.value
                                        )
                                        if (!rentalPeriodPrice) return null
                                        return (
                                            <Button
                                                variant="select"
                                                selected={selected}
                                                fullwidth
                                                as="div"
                                                size="md"
                                            >
                                                <Flex
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    justifyContent="space-between"
                                                >
                                                    <div
                                                        style={!selected ? { fontWeight: 300 } : {}}
                                                    >
                                                        {option.value} {localize(monthsLong)}
                                                    </div>
                                                    <div>
                                                        {formatAmount(
                                                            rentalPeriodPrice.price.total
                                                        )}
                                                        /{localize(monthsShort)}
                                                    </div>
                                                </Flex>
                                            </Button>
                                        )
                                    }}
                                    onChange={async (e, val) => {
                                        setPhone(
                                            await postPhoneOffer("rentalPeriod", {
                                                ...phone,
                                                rentalPeriod: val,
                                            })
                                        )
                                    }}
                                />
                            </Flex>
                        </div>
                        <Divider horizontal spacing={0} />
                        <Flex
                            alignItems="baseline"
                            gap="4px 8px"
                            style={{
                                whiteSpace: "nowrap",
                                flexWrap: "wrap",
                            }}
                        >
                            <Text variant="heading" level={["2", ["min", "md", "3"]]} color="brand">
                                {formatAmount(phone.monthlyPrice.total)}/{localize(monthsShort)}
                            </Text>
                            <Text variant="body" size="md">
                                {MustacheString(localize(section.configure.totalPricePostFix), {
                                    rentalPeriod: `${phone.rentalPeriod}`,
                                })}
                            </Text>
                            <Text variant="body" size="sm" color="gray300">
                                {MustacheString(
                                    localize(section.configure.rentalPeriodTotalPrice),
                                    {
                                        rentalPeriod: `${phone.rentalPeriod.valueOf()}`,
                                        totalPrice: `${formatAmount(phone.rentalPeriodTotalPrice)}`,
                                        monthsShort: localize(monthsShort),
                                        monthsLong: localize(monthsLong),
                                    }
                                )}
                            </Text>
                        </Flex>
                        <Button
                            variant="primary"
                            href={
                                me.data?.orderLimitReached
                                    ? undefined
                                    : localizeSlug(checkoutPage.data.slug)
                            }
                            fullwidth
                            onClick={
                                me.data?.orderLimitReached
                                    ? handleOrderLimitReachedCtaClick
                                    : () => {
                                          if (typeof fbq !== "undefined") {
                                              fbq("track", "AddToCart", {
                                                  content_ids: [phone.id.valueOf()],
                                                  content_name: localize(phone.name),
                                                  content_type: "product",
                                                  value: phone.monthlyPrice.total,
                                                  currency: phone.monthlyPrice.total.currency,
                                                  num_items: 1,
                                              })
                                          }
                                      }
                            }
                        >
                            {localize(section.configure.cta.buttonText)}
                        </Button>
                        <Modal
                            width={600}
                            isOpen={showOrderLimitReachedModal}
                            header={{ title: localize(section.orderLimitReachedModal.title) }}
                        >
                            <Flex direction="column" gap={24}>
                                <MarkdownView
                                    value={localize(section.orderLimitReachedModal.text)}
                                />
                                <Flex justifyContent="flex-end">
                                    <Button
                                        variant="primary"
                                        size="md"
                                        onClick={() => setShowOrderLimitReachedModal(false)}
                                    >
                                        {localize(section.orderLimitReachedModal.closeButtonText)}
                                    </Button>
                                </Flex>
                            </Flex>
                        </Modal>
                    </Flex>
                </Flex>
                {section.notifications?.map((n) => (
                    <Notification
                        key={n.id.valueOf()}
                        icon={n.icon}
                        text={localize(n.text)}
                        color={n.color}
                    />
                ))}

                {section.steps && (
                    <StepsBlock
                        heading={localize(section.steps.heading)}
                        items={section.steps.items.map((item) => ({
                            id: item.id.valueOf(),
                            text: localize(item.text),
                        }))}
                        image={section.steps.image}
                    />
                )}
                {section.benefits && (
                    <BenefitsBlock
                        heading={localize(section.benefits.heading)}
                        items={section.benefits.items.map((item) => ({
                            id: item.id.valueOf(),
                            icon: item.icon,
                            heading: localize(item.heading),
                            text: localize(item.text),
                        }))}
                    />
                )}
                {!!section.frequentlyAskedQuestions &&
                    frequentlyAskedQuestions.data.questions.length > 0 && (
                        <FaqBlock
                            heading={localize(section.frequentlyAskedQuestions.heading)}
                            questions={frequentlyAskedQuestions.data.questions.map((item) => ({
                                id: item.id,
                                question: localize(item.question),
                                answer: localize(item.answer),
                            }))}
                            showAllButtonText={localize(
                                section.frequentlyAskedQuestions.showAllButtonText
                            )}
                            maxInitialQuestions={section.frequentlyAskedQuestions.maxInitialQuestions?.valueOf()}
                        />
                    )}
            </Flex>
        </Flex>
    )
}
