import { ReactNode, useRef, useState } from "react"
import { motion, useInView } from "framer-motion"
import { responsiveCss, responsiveSectionSpacing, responsiveSpacing } from "../../helpers/css"
import { springAnimations } from "../../constants/animation"
import { css } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { Heading } from "../typography/Heading"
import { Button } from "../buttons/Button"
import { CarouselBasePosition, CarouselBaseRef } from "../base/CarouselBase"
import { CardsCarousel } from "../carousel/CardsCarousel"
import { pageWidth, screenSizes } from "../../constants/sizes"

type CardsBlockProps<T> = {
    heading?: string
    showSlideButtons?: boolean
    cards: T[]
    renderCard: (card: T, index: number) => ReactNode
    enterAnimation?: boolean
}

const GRID_COLUMN_COUNT = 4

export function CardsBlock<T>(props: CardsBlockProps<T>) {
    const containerRef = useRef<HTMLDivElement>(null)
    const isInView = useInView(containerRef, { once: true, amount: 0.25 })
    const [position, setPosition] = useState<CarouselBasePosition>({
        atStart: true,
        atEnd: false,
    })
    const carouselBaseRef = useRef<CarouselBaseRef>(null)

    return (
        <div
            ref={containerRef}
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                },
                responsiveSectionSpacing()
            )}
        >
            <Flex
                alignItems="center"
                justifyContent="space-between"
                css={css({ marginBottom: 16 }, responsiveCss("min", "md", { marginBottom: 24 }))}
            >
                <Heading level={2} padding={{ y: 8 }} truncate>
                    {props.heading}
                </Heading>
                {props.showSlideButtons && (
                    <Flex shrink={0} margin={{ left: 16 }}>
                        <Button
                            size="sm"
                            variant="secondary"
                            iconStart="chevronLeft"
                            margin={{ right: 4 }}
                            disabled={position.atStart}
                            onClick={() => carouselBaseRef.current?.prev()}
                        />
                        <Button
                            size="sm"
                            variant="secondary"
                            iconStart="chevronRight"
                            disabled={position.atEnd}
                            onClick={() => carouselBaseRef.current?.next()}
                        />
                    </Flex>
                )}
            </Flex>
            <div
                css={responsiveSpacing("sm", "--gap")}
                style={{
                    overflow: "hidden",
                    width: "100vw",
                    marginLeft: "calc(50% - 50vw)",
                }}
            >
                <CardsCarousel
                    ref={carouselBaseRef}
                    onPositionChange={(p) => {
                        if (p.atStart !== position.atStart || p.atEnd !== position.atEnd) {
                            setPosition(p)
                        }
                    }}
                    items={props.cards}
                    renderContainer={(children, scrollContainerRef) => (
                        <motion.div
                            ref={scrollContainerRef}
                            style={{
                                overflowX: "scroll",
                                scrollSnapType: "x mandatory",
                                scrollbarWidth: "none",
                            }}
                            css={css(css`
                                --padding-inline: ${pageWidth.xs.paddingLeft}px;
                                @media (min-width: ${screenSizes.md}px) {
                                    --padding-inline: ${pageWidth.md.paddingLeft}px;
                                }
                                @media (min-width: ${pageWidth.xs.maxWidth}px) {
                                    --padding-inline: calc(
                                        ((100vw - ${pageWidth.xs.maxWidth}px) / 2) +
                                            ${pageWidth.md.paddingLeft}px
                                    );
                                }
                                min-width: 100%;
                                padding: 0 var(--padding-inline);
                                scroll-padding: 0 var(--padding-inline);
                                &::-webkit-scrollbar {
                                    display: none;
                                }
                                display: grid;
                                grid-auto-flow: column;
                                grid-auto-columns: minmax(min-content, 1fr);
                                @media (min-width: ${screenSizes.md}px) {
                                    grid-auto-columns: calc(
                                        calc(
                                                ${pageWidth.xs.maxWidth}px -
                                                    ${pageWidth.md.paddingLeft}px -
                                                    ${pageWidth.md.paddingRight}px - calc(
                                                        var(--gap) * ${GRID_COLUMN_COUNT - 1}
                                                    )
                                            ) / ${GRID_COLUMN_COUNT}
                                    );
                                }
                            `)}
                            initial={{
                                transform: "translateX(200px)",
                                gap: "64px",
                                opacity: 0,
                            }}
                            animate={
                                (isInView || !props.enterAnimation) && {
                                    transform: "translateX(0px)",
                                    gap: "var(--gap)",
                                    opacity: 1,
                                }
                            }
                            transition={
                                props.enterAnimation ? springAnimations["600"] : { duration: 0 }
                            }
                        >
                            {children}
                        </motion.div>
                    )}
                    renderItem={props.renderCard}
                />
            </div>
        </div>
    )
}
