import {
    ForwardedRef,
    ReactNode,
    RefObject,
    createRef,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react"
import { motion, PanInfo, useMotionValue } from "framer-motion"
import { server } from "../../../../../../server"
import { Image, Markdown } from "../../../../../../reactor"
import { Component } from "../../../../../../packages/editing/Component"
import { WithOverlayCard } from "../cards/WithOverlay"
import { springAnimations } from "../../constants/animation"

export type CarouselBasePosition = {
    atStart: boolean
    atEnd: boolean
}

type CarouselBaseProps<T> = {
    items: T[]
    onPositionChange?: (position: CarouselBasePosition) => void
    renderContainer: (children: ReactNode) => ReactNode
    renderItem: (item: T, index: number) => ReactNode
}

export type CarouselBaseRef = {
    next: () => void
    prev: () => void
}

// When swiping sideways, the minimum width of a card that should be visible before snapping to it.
const currentCardVisibleMinimum = 0.25

/**
 * Base carousel that takes a set of cards.
 */
export const CarouselBase = forwardRef(function CarouselBase<T>(
    props: CarouselBaseProps<T>,
    ref: ForwardedRef<CarouselBaseRef>
) {
    const dragContainerRef = useRef<HTMLDivElement>(null)
    const cardsContainerRef = useRef<HTMLDivElement>(null)
    const cardsRef = useRef<RefObject<HTMLDivElement>[]>(props.items.map(() => createRef()))
    const dragX = useMotionValue(0)
    const [offsetX, setOffsetX] = useState(0)
    const [dragOffsetX, setDragOffsetX] = useState(0)
    const [position, setPosition] = useState({ atStart: true, atEnd: false })

    const updateOffsetX = useCallback(
        (o: number) => {
            const newOffset = o
            setOffsetX(newOffset)
            // As Framer Motion also keeps the drag offset applied, we need to store that.
            setDragOffsetX(dragX.get())
        },
        [setOffsetX, dragX]
    )

    // Returns a set of DOMRects used frequently in the component.
    function getRects() {
        const container = dragContainerRef.current?.getBoundingClientRect()
        const grid = cardsContainerRef.current?.getBoundingClientRect()
        const firstCard = cardsRef.current[0]?.current?.getBoundingClientRect()
        const lastCard =
            cardsRef.current[cardsRef.current.length - 1]?.current?.getBoundingClientRect()

        if (!container || !grid || !firstCard || !lastCard) {
            // eslint-disable-next-line no-console
            console.warn("Missing reference to container, card, or grid.")
            return
        }

        return {
            container,
            grid,
            firstCard,
            lastCard,
        }
    }

    const updatePosition = useCallback(() => {
        const rects = getRects()
        if (!rects) return

        const newPosition = {
            atStart: Math.round(rects.container.left) === Math.round(rects.firstCard.left),
            atEnd: Math.round(rects.container.right) === Math.round(rects.lastCard.right),
        }
        if (newPosition.atStart === position.atStart && newPosition.atEnd === position.atEnd) return

        setPosition(newPosition)
    }, [position])

    // Update position object whenever offsetX changes.
    useEffect(() => {
        updatePosition()
    }, [offsetX, updatePosition])

    const { onPositionChange } = props
    // Call onPositionChange when position changes.
    useEffect(() => {
        onPositionChange?.(position)
    }, [position, onPositionChange])

    // Ref functions that allow other components consuming this one to trigger next/prev switch.
    useImperativeHandle(ref, () => ({
        next() {
            handleSlideClick("next")
        },
        prev() {
            handleSlideClick("prev")
        },
    }))
    const debouncedUpdatePosition = useMemo(() => debounce(updatePosition, 500), [updatePosition])

    const resizeHandler = useCallback(() => {
        const rects = getRects()
        if (!rects) return

        const atEnd = rects.lastCard.right < rects.container.right
        if (atEnd) {
            const newOffsetX = rects.firstCard.left - (rects.lastCard.right - rects.container.width)
            updateOffsetX(newOffsetX)
        }
        debouncedUpdatePosition()
    }, [updateOffsetX, debouncedUpdatePosition])

    useEffect(() => {
        const resizeObserver = new ResizeObserver(resizeHandler)
        resizeObserver.observe(document.body)
        return () => {
            resizeObserver.disconnect()
        }
    }, [resizeHandler])

    useEffect(() => {
        // The intersection observer is used to observe when any of the cards intersect with
        // the container.
        const intersectionObserver = new IntersectionObserver(debouncedUpdatePosition, {
            root: dragContainerRef.current,
            rootMargin: "0px",
            threshold: 1.0,
        })

        for (const c of cardsRef.current) {
            if (c.current) intersectionObserver.observe(c.current)
        }

        // We need the mutation observer to observe for changes in position controlled by style
        // attribute of child elements. Because of that it's difficult to trigger in a better way.
        const mutationObserver = new MutationObserver(debouncedUpdatePosition)

        if (cardsContainerRef.current) {
            mutationObserver.observe(cardsContainerRef.current, {
                attributes: true,
                subtree: true,
            })
        }

        return () => {
            mutationObserver.disconnect()
            intersectionObserver.disconnect()
        }
    }, [debouncedUpdatePosition])

    // Find the index of the (first) card that should be presented as the current card.
    const findCurrentCardIndex = useCallback((info: PanInfo) => {
        const containerRect = dragContainerRef.current?.getBoundingClientRect()
        if (!containerRect) {
            // eslint-disable-next-line no-console
            console.warn(
                "Unable to find current card index without a reference to the container element."
            )
            return
        }

        let returnIndex: undefined | number
        return cardsRef.current.findIndex((item, index) => {
            if (returnIndex === index) {
                returnIndex = undefined
                return true
            }
            const itemRect = item.current?.getBoundingClientRect()
            if (!itemRect) throw new Error(`No element for card item at index ${index}.`)

            // The first card is dragged to the right of the left edge of the container
            if (index === 0 && itemRect.left > containerRect.left) return true

            const nextRect = cardsRef.current[index + 1]?.current?.getBoundingClientRect()
            if (!nextRect) return true

            const isLeftEdgeInside =
                itemRect.left > containerRect.left && itemRect.left < containerRect.right
            const isRightEdgeInside =
                itemRect.right > containerRect.left && itemRect.right < containerRect.right

            // If neither the left nor the right edge is inside this is not the card.
            if (!isLeftEdgeInside && !isRightEdgeInside) return false

            // If the whole card is inside, this is it.
            if (isLeftEdgeInside && isRightEdgeInside) return true

            if (info.offset.x < 0) {
                // Dragged towards the left.
                // Some but less than `currentCardVisibleMinimum` of right side is inside, move to next.
                const pmoveToNext = !isLeftEdgeInside && isRightEdgeInside
                if (pmoveToNext) {
                    const moveToNext =
                        itemRect.right - containerRect.left <
                        itemRect.width - itemRect.width * currentCardVisibleMinimum
                    if (moveToNext) {
                        returnIndex = index + 1
                        return false
                    }
                }
                // If more than right half is visible, this is it.
                const rightHalfPlusInside =
                    isRightEdgeInside &&
                    itemRect.right - containerRect.left > itemRect.width * currentCardVisibleMinimum
                if (rightHalfPlusInside) return true
                // If none of the above is the case for any cards until now, this is the one.
                const leftHalfPlusInside =
                    isLeftEdgeInside &&
                    containerRect.right - itemRect.left > itemRect.width * currentCardVisibleMinimum
                if (leftHalfPlusInside) return true
            } else if (info.offset.x > 0) {
                // Dragged towards the right
                const rightThirdPlusInside =
                    isRightEdgeInside &&
                    itemRect.right - containerRect.left > itemRect.width * currentCardVisibleMinimum
                if (rightThirdPlusInside) return true
            }

            return false
        })
    }, [])

    const handleDragEnd = useCallback(
        (e: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
            const containerRect = dragContainerRef.current?.getBoundingClientRect()
            const gridRect = cardsContainerRef.current?.getBoundingClientRect()
            const firstCardRect = cardsRef.current[0]?.current?.getBoundingClientRect()
            const lastCardRect =
                cardsRef.current[cardsRef.current.length - 1]?.current?.getBoundingClientRect()
            if (!containerRect || !gridRect || !firstCardRect || !lastCardRect) {
                // eslint-disable-next-line no-console
                console.warn("Missing reference to container or grid.")
                return
            }

            // The actual width of the cards. As opposed to the rect width, which is cut off at the
            // overflow point.
            const cardsContainerActualWidth = lastCardRect.right - firstCardRect.left
            // If the whole grid is visible, no need to set any offset, but as always take dragX
            // into account.
            if (cardsContainerActualWidth <= containerRect.width) {
                updateOffsetX(0)
                return
            }

            // Last card is to the right side of container edge.
            if (lastCardRect.right < containerRect.right) {
                const newOffsetX = firstCardRect.left - (lastCardRect.right - containerRect.width)
                updateOffsetX(newOffsetX)
                return
            }

            const currentCardIndex = findCurrentCardIndex(info)
            if (typeof currentCardIndex === "undefined") {
                // eslint-disable-next-line no-console
                console.warn("Couldn't find a current card index. Reset offset and dragX.")
                updateOffsetX(0)
                dragX.set(0)
                return
            }

            const currCardRect =
                cardsRef.current[currentCardIndex]?.current?.getBoundingClientRect()
            if (!firstCardRect || !currCardRect) {
                // eslint-disable-next-line no-console
                console.warn("Missing reference to first card or current card.")
                return
            }

            // If the width from the current card to the last card is less than the container, align
            // the last card to the right side.
            if (lastCardRect.right - currCardRect.left < containerRect.width) {
                const newOffsetX = firstCardRect.left - (lastCardRect.right - containerRect.width)
                updateOffsetX(newOffsetX)
                return
            }

            // Find new offset. It is usually the distance from the first card to the current card.
            // Except if current card is the last card, as that should not be aligned with the left
            // side of the container, but the right side.
            const newOffsetX =
                firstCardRect.left -
                (currentCardIndex === props.items.length - 1
                    ? currCardRect.right - containerRect.width
                    : currCardRect.left)

            updateOffsetX(newOffsetX)
        },
        [updateOffsetX, findCurrentCardIndex, dragX, props.items.length]
    )

    // Just copied from CardsBlock, probably a good candidate for rafactoring.
    const handleSlideClick = useCallback(
        (direction: "prev" | "next") => {
            if (!dragContainerRef.current) return

            // Direction is the direction the cards will slide in, which is the opposite of the way the
            // arrow on the button is pointing.
            const slideDirection = direction === "prev" ? "right" : "left"
            const containerRect = dragContainerRef.current.getBoundingClientRect()

            try {
                // Find first card that is partially on the inside and partially on the outside of
                // container. Or the card before a card that is just inside the container This is
                // the card we'll want to move to the edge of the container.
                const firstCardPartiallyOutside = cardsRef.current.find((item, index) => {
                    const itemRect = item.current?.getBoundingClientRect()
                    if (!itemRect) throw new Error(`No element for card item at index ${index}.`)
                    if (slideDirection === "left") {
                        return itemRect.right > containerRect.right
                    } else {
                        const nextRect =
                            cardsRef.current[index + 1]?.current?.getBoundingClientRect()

                        if (!nextRect) {
                            return true
                        }

                        return (
                            (itemRect.left < containerRect.left &&
                                Math.round(nextRect.left) === Math.round(containerRect.left)) ||
                            (itemRect.left < containerRect.left &&
                                itemRect.right > containerRect.left)
                        )
                    }
                })

                const firstCardPartiallyOutsideRect =
                    firstCardPartiallyOutside?.current?.getBoundingClientRect()

                if (!firstCardPartiallyOutsideRect) {
                    throw new Error("Couldn't find rect for first card outside container.")
                }

                // Find if remaining width outside is less than will fit in container.
                const outerMostCardRect = (
                    slideDirection === "left"
                        ? cardsRef.current[cardsRef.current.length - 1]
                        : cardsRef.current[0]
                )?.current?.getBoundingClientRect()

                if (!outerMostCardRect) throw new Error("No outermost card.")

                const remainingWidthOutside =
                    slideDirection === "left"
                        ? outerMostCardRect.right - containerRect.right
                        : containerRect.left - outerMostCardRect.left

                if (remainingWidthOutside < containerRect.width) {
                    updateOffsetX(slideDirection === "left" ? offsetX - remainingWidthOutside : 0)
                } else {
                    // Else, scroll to where the first card partially outside, will be at the
                    // container edge in the slide direction.
                    const distanceFromCardToContainerEdge =
                        containerRect[slideDirection] -
                        firstCardPartiallyOutsideRect[slideDirection]

                    updateOffsetX(offsetX + distanceFromCardToContainerEdge)
                    return
                }
                return
            } catch (err) {
                //eslint-disable-next-line no-console
                console.log(err)
            }
        },
        [offsetX, updateOffsetX]
    )

    return (
        <div ref={dragContainerRef}>
            <motion.div
                drag="x"
                dragDirectionLock
                dragMomentum={false}
                onDragEnd={handleDragEnd}
                transition={springAnimations["200"]}
                animate={{
                    translateX: `${offsetX - dragOffsetX}px`,
                }}
                onAnimationComplete={() => updatePosition()}
                style={{ x: dragX }}
            >
                <div ref={cardsContainerRef}>
                    {props.renderContainer(
                        props.items.map((item, index) => (
                            <div key={index} ref={cardsRef.current[index]}>
                                {props.renderItem(item, index)}
                            </div>
                        ))
                    )}
                </div>
            </motion.div>
        </div>
    )
})

type Demo = string
const arr: Demo[] = [...new Array(3)].map(() => "Hello, World!")

Component(CarouselBase as any, {
    name: "CarouselBase",
    gallery: {
        items: [
            {
                variants: [
                    {
                        props: {
                            items: arr,
                            renderContainer: (children: any) => (
                                <div
                                    style={{
                                        display: "grid",
                                        gridAutoFlow: "column",
                                        gap: 16,
                                        gridAutoColumns: "min-content",
                                    }}
                                >
                                    {children}
                                </div>
                            ),
                            renderItem: (_: any, index: any) => (
                                <WithOverlayCard
                                    title={`Hello ${index}${index}${index}${index}`}
                                    text={Markdown(`${index}`)}
                                    image={
                                        `${server()}/static/redoit/how-it-works-card-illustration-3.svg` as any as Image
                                    }
                                    readMoreAriaPrefix="Read more"
                                />
                            ),
                        },
                    },
                ],
            },
        ],
    },
})

function debounce(callback: (...args: any[]) => void, wait: number) {
    let timeoutId: number | undefined
    return (...args: any[]) => {
        if (typeof window === "undefined") callback(...args)
        window.clearTimeout(timeoutId)
        timeoutId = window.setTimeout(() => {
            callback(...args)
        }, wait)
    }
}
