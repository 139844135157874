import { ImgHTMLAttributes } from "react"
import { Image, ImageToUrl } from "../../../../../../reactor"

/**
 * Component that renders an img with srcSet generated based on predefined pixel densities.
 */
export function ResponsiveImage(
    props: ImgHTMLAttributes<HTMLImageElement> & { image: Image; width: number }
) {
    const { image, width, ...rest } = props
    return (
        <img
            srcSet={[1, 1.5, 2, 3]
                .map((x) => `${ImageToUrl(image, { width: width * x })} ${x}x`)
                .join(", ")}
            src={ImageToUrl(props.image, { width })}
            {...rest}
        />
    )
}
