import { ReactNode } from "react"

/**
 * Registers a component.
 *
 * Use the type `Component` to represent any of the registered component types. This avoids having
 * to declare a union type of all component types.
 *
 * @param type The type of component to render.
 * @param render A function component that renders the component.
 */
export function Component<T>(render: ComponentRender<T>, options: ComponentOptions<T>): void {
    let name = typeof options.name === "string" ? options.name : render.name

    if (!render) {
        throw new Error("Component render must be a function")
    }

    if (name.includes("$")) {
        // This name has been changed by Parcel's scope hoisting. We need to extract the original
        // name.
        const parts = name.split("$")

        if (parts.includes("export")) {
            throw new Error(
                "Exported component function must specify name (the name is lost due to Parcel's scope hoisting)"
            )
        }

        name = parts[parts.length - 1]
    }

    const componentAlreadyRegistered = Component.all.has(name)

    Component.all.set(name, { render, options })

    const invalidateFuncs = Component.invalidators.get(name)

    if (componentAlreadyRegistered && invalidateFuncs) {
        setTimeout(() => {
            for (const invalidate of invalidateFuncs) {
                invalidate()
            }
        }, 1)
    }
}

/**
 * A function component that renders a component.
 */
export type ComponentRender<T = {}> = (
    props: T,

    /**
     * The index of this component, if rendered in a list of components
     */
    index?: number,

    /**
     * The total number of components, if rendered in a context of list of components.
     */
    totalCount?: number
) => JSX.Element

type ComponentOptions<T> = {
    name?: string

    /**
     * Set to `true` if this component is a section.
     */
    section?: boolean

    /**
     * Gallery config for this component if to be included in the gallery.
     */
    gallery?: Gallery<T>
}

/**
 * Type used to define the gallery for a component
 */
type Gallery<T> = {
    /**
     * A short text about the component.
     */
    about?: string

    /**
     * The path within the gallery to the component, e.g. `typography/heading`.
     * Leading/trailing slashes will be stripped. Use this if you don't just want a flat structure
     * where all components are on the same level.
     */
    path?: string

    /**
     * All items in the gallery for the component. Use several items to present the component with
     * different props for example.
     */
    items: {
        /**
         * The title for the item.
         */
        title?: string

        /**
         * A short description of the item for that helps the user understand what the current item
         * is showing, like "A button to use for primary actions.".
         */
        description?: string

        /**
         * Relevant variants of the component to present side by side. For example to show the same
         * button in different states.
         */
        variants: (
            | {
                  /**
                   * The label for the current variant.
                   */
                  label?: string

                  /**
                   * The props to pass to the component for the current item and variant.
                   */
                  props: T | ((state: any, setState: (state: any) => any) => T)

                  /**
                   * Show how the component looks in a pseudo state, like a button with focus.
                   */
                  pseudo?: ":hover" | ":focus" | ":active" | ":visited"

                  /**
                   * Custom render function.
                   */
                  render?: (cmp: ReactNode, state: any, setState: (state: any) => any) => ReactNode
              }
            | undefined
            | null
            | false
        )[]
    }[]
}

Component.all = new Map<string, { render: ComponentRender<any>; options: ComponentOptions<any> }>()

/**
 * Hot reload callbacks.
 *
 * Since component types are not components directly, their wrappers need to be
 * invalidated. This is done by calling the invalidate function when a component
 * type is re-registered.
 */
Component.invalidators = new Map<string, (() => void)[]>()
