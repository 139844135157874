import { useCallback, useContext, useState } from "react"
import { motion } from "framer-motion"
import { css, responsiveCss, scaleValue } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { Icon, IconName } from "../visual/Icon"
import { Box } from "../base/Box"
import { colors, Color } from "../../constants/colors"
import { boxShadow } from "../../constants/shadow"
import { Tooltip } from "../base/Tooltip"
import { Component } from "../../../../../../packages/editing/Component"
import { Text } from "../typography/Text"
import { CheckoutContext, CheckoutStep } from "../../../sections/checkout/CheckoutContext"

type NavigationStep<T extends string> = {
    id: T
    icon: IconName
    name: string
    isAvailable: boolean
}

const CURRENT_ICON_DIMENSION = 48
const MOBILE_OUTER_ICON_DIMENSION = 16
const MOBILE_INNER_ICON_DIMENSION = 12
const DESKTOP_OUTER_ICON_DIMENSION = 40
const DESKTOP_INNER_ICON_DIMENSION = 32

type StepsProps<T extends string> = {
    steps: NavigationStep<T>[]
    currentStep: T
}

export function StepsNavigation<T extends string>({ steps, currentStep }: StepsProps<T>) {
    const checkoutContext = useContext(CheckoutContext)
    const getStatus = (step: T, index: number): StepStatus => {
        const currentIndex = steps.findIndex((s) => s.id === step)

        if (currentIndex === index) return "current"
        if (currentIndex > index) return "completed"
        return "future"
    }

    const getPosition = (index: number, length: number) => {
        if (index === 0) return "first"
        if (index === length) return "last"
        return "middle"
    }

    const handleClick = useCallback(
        (s: T) => {
            checkoutContext?.setStep(s as CheckoutStep)
        },
        [checkoutContext]
    )

    return (
        <Flex
            flex="1"
            alignItems="center"
            justifyContent="space-between"
            style={{ position: "relative" }}
        >
            <StepSeparator
                currentStepIndex={steps.findIndex((s) => s.id === currentStep)}
                totalSteps={steps.length}
            />
            {steps.map((step, index) => {
                const status = getStatus(currentStep, index)
                return (
                    <StepIcon
                        onClick={step.isAvailable ? () => handleClick(step.id) : undefined}
                        step={step}
                        status={status}
                        position={getPosition(index, steps.length - 1)}
                        key={step.id}
                    />
                )
            })}
        </Flex>
    )
}

type StepStatus = "completed" | "current" | "future"

type StepIconProps<T extends string> = {
    onClick?: () => void
    step: NavigationStep<T>
    status: StepStatus
    position: "first" | "middle" | "last"
}

const stepIndicatorColorMap: Record<StepStatus, Color> = {
    completed: "forest",
    current: "grayWhite",
    future: "gray200",
}

function StepIcon<T extends string>({ step, status, position, onClick }: StepIconProps<T>) {
    const getDimension = (dimension: number) =>
        scaleValue(status === "current" ? CURRENT_ICON_DIMENSION : dimension)

    return (
        <motion.div
            onClick={onClick}
            css={css(
                {
                    backgroundColor: colors.gray100,
                    cursor: onClick ? "pointer" : "inherit",
                    boxShadow: status === "current" ? boxShadow : "none",
                    height: getDimension(DESKTOP_OUTER_ICON_DIMENSION),
                    width: getDimension(DESKTOP_OUTER_ICON_DIMENSION),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    borderRadius: "50%",
                },
                responsiveCss("max", "sm", {
                    height: getDimension(MOBILE_OUTER_ICON_DIMENSION),
                    width: getDimension(MOBILE_OUTER_ICON_DIMENSION),
                    marginRight:
                        status !== "current" && position === "last"
                            ? scaleValue((CURRENT_ICON_DIMENSION - MOBILE_OUTER_ICON_DIMENSION) / 2)
                            : undefined,
                    marginLeft:
                        status !== "current" && position === "first"
                            ? scaleValue((CURRENT_ICON_DIMENSION - MOBILE_OUTER_ICON_DIMENSION) / 2)
                            : undefined,
                })
            )}
        >
            <Tooltip
                alignment="center"
                renderTrigger={(ref) => (
                    <motion.div
                        whileHover="hover"
                        variants={{
                            hover:
                                status === "future"
                                    ? {
                                          backgroundColor: colors.grayWhite,
                                          transition: { duration: 0.2 },
                                      }
                                    : {},
                        }}
                        ref={ref as any}
                        css={css(
                            {
                                backgroundColor: colors[stepIndicatorColorMap[status]],
                                height: getDimension(DESKTOP_INNER_ICON_DIMENSION),
                                width: getDimension(DESKTOP_INNER_ICON_DIMENSION),
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                            },
                            responsiveCss("max", "sm", {
                                height: getDimension(MOBILE_INNER_ICON_DIMENSION),
                                width: getDimension(MOBILE_INNER_ICON_DIMENSION),
                            })
                        )}
                    >
                        {status === "completed" ? (
                            <Icon hidden={["max", "sm"]} icon="check" color="grayWhite" />
                        ) : status === "current" ? (
                            <Icon icon={step.icon} />
                        ) : (
                            <motion.div
                                style={{ opacity: 0, scale: 0.1 }}
                                variants={{
                                    hover: {
                                        opacity: 1,
                                        scale: 0.8,
                                        transition: { duration: 0.2 },
                                    },
                                }}
                            >
                                <Icon icon={step.icon} />
                            </motion.div>
                        )}
                    </motion.div>
                )}
            >
                <Text variant="heading" level="4">
                    {step.name}
                </Text>
            </Tooltip>
        </motion.div>
    )
}

type StepSeparatorProps = {
    currentStepIndex: number
    totalSteps: number
}

const StepSeparator = ({ currentStepIndex, totalSteps }: StepSeparatorProps) => {
    const percentage = (currentStepIndex / (totalSteps - 1)) * 100
    const uncompletedBarWidth = `${100 - percentage}%`
    const completedBarWidth = `${percentage}%`

    return (
        <div
            css={css({
                height: 4,
                position: "absolute",
                right: `${CURRENT_ICON_DIMENSION / 2}px`,
                left: `${CURRENT_ICON_DIMENSION / 2}px`,
                display: "flex",
                overflow: "hidden",
            })}
        >
            <div
                css={css(
                    {
                        height: "100%",
                        width: completedBarWidth,
                        backgroundColor: colors.forest,
                        transition: "width 0.8s ease-in-out",
                        borderRadius: "2px",
                    },
                    responsiveCss("min", "md", {
                        width: `calc(${completedBarWidth} + ${(2 * CURRENT_ICON_DIMENSION) / 3}px)`,
                    })
                )}
            />
            <div
                css={css(
                    {
                        height: "100%",
                        width: uncompletedBarWidth,
                        backgroundColor: colors.gray200,
                        transition: "width 0.8s ease-in-out",
                    },
                    responsiveCss("min", "md", {
                        width: `calc(${uncompletedBarWidth} - ${(2 * CURRENT_ICON_DIMENSION) / 3}px)`,
                    })
                )}
            />
        </div>
    )
}

const navigationMock = [
    {
        id: "TradeIn",
        name: "TradeIn",
        icon: "handCoins",
        tooltip: "Bytte inn",
        isAvailable: true,
    },
    {
        id: "Insurance",
        name: "Insurance",
        icon: "shield",
        tooltip: "Trygghetspakke",
        isAvailable: true,
    },
    {
        id: "Login",
        name: "Login",
        icon: "vipps",
        tooltip: "Vipps ID",
        isAvailable: false,
    },
    {
        id: "Shipping",
        name: "Shipping",
        icon: "truck",
        tooltip: "Shipping",
        isAvailable: false,
    },
]

Component(StepsNavigation, {
    name: "Steps",
    gallery: {
        path: "Navigation/Steps",
        items: [
            {
                title: "First Step",
                variants: [
                    {
                        props: {
                            steps: navigationMock as NavigationStep<string>[],
                            currentStep: "TradeIn",
                        },
                    },
                ],
            },
            {
                title: "Second Step",
                variants: [
                    {
                        props: {
                            steps: navigationMock as NavigationStep<string>[],
                            currentStep: "Insurance",
                        },
                    },
                ],
            },
            {
                title: "Third Step",
                variants: [
                    {
                        props: {
                            steps: navigationMock as NavigationStep<string>[],
                            currentStep: "Login",
                        },
                    },
                ],
            },
            {
                title: "Last Step",
                variants: [
                    {
                        props: {
                            steps: navigationMock as NavigationStep<string>[],
                            currentStep: "Shipping",
                        },
                    },
                ],
            },
        ],
    },
})
