import { css } from "@emotion/react"
import { useAnimate } from "framer-motion"
import { ReactNode, useEffect, useRef, useState } from "react"

export function Collapsible({ collapsed, children }: { collapsed: boolean; children: ReactNode }) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [scope, animate] = useAnimate()
    const [autoHeight, setAutoHeight] = useState(false)

    useEffect(() => {
        if (!containerRef.current?.clientHeight) return

        const height = containerRef.current.clientHeight
        setAutoHeight(false)

        void animate(scope.current, {
            height: collapsed ? 0 : `${height}px`,
            opacity: collapsed ? 0 : 1,
        }).then(() => {
            if (!collapsed) {
                setAutoHeight(true)
            }
        })
    }, [animate, collapsed, scope])

    return (
        <div
            ref={scope}
            css={css({
                overflow: collapsed ? "hidden" : "visible",
                height: autoHeight ? "auto !important" : undefined,
            })}
        >
            <div ref={containerRef}>{children}</div>
        </div>
    )
}
