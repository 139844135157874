import { Section } from "../../../../packages/editing/Section"
import { Color as ReactorColor, Image } from "../../../../reactor"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { useWebPageInfo } from "../../../../studio/client"
import { Page } from "../../model/Page"
import { PromoCard as PromoCardComponent } from "../ui/components/cards/PromoCard"
import { PhoneModel } from "../../model/PhoneModel"
import { Color } from "../ui/constants/colors"
import { ProductDetailsSearchParams } from "./ProductDetails"
import { usePhoneOffers } from "../client"
import { MustacheString } from "../../../../packages/editing/Mustache"
import { monthsShort, useFormatAmount } from "../ui/constants/text"

function PromoCard(section: {
    heading: Localized<string>
    /**
     * @default gray500
     */
    textColor: Color
    cta: {
        /**
         * Available variables are {{fromPrice}}, {{monthsShort}}.
         * @default '{"no": "Fra {{fromPrice}}/{{monthsShort}}"}'
         */
        text: Localized<string>
        page: Page["id"]
        phone: PhoneModel["id"]

        /**
         * @default light
         */
        buttonVariant: "light" | "dark"
        /**
         * @default '{"no": "Utsolgt", "en": "Sold out"}'
         */
        soldOutText: Localized<string>
    }
    image?: Image
    backgroundColor?: ReactorColor
}) {
    const localize = useLocalize()
    const ctaPageInfo = useWebPageInfo(section.cta.page)
    const phoneOffers = usePhoneOffers([section.cta.phone], undefined, undefined, undefined)
    const phoneOffer = phoneOffers.data?.at(0)
    const formatAmount = useFormatAmount()

    if (!ctaPageInfo.data) {
        return <></>
    }

    return (
        <PromoCardComponent
            heading={localize(section.heading)}
            image={section.image}
            backgroundColor={section.backgroundColor}
            cta={{
                buttonVariant: section.cta.buttonVariant,
                url:
                    phoneOffer && ctaPageInfo.data.slug
                        ? `/${localize(ctaPageInfo.data.slug)}?${ProductDetailsSearchParams({ phoneId: phoneOffer.id })}`
                        : "/",
                text: !phoneOffer
                    ? localize(section.cta.soldOutText)
                    : MustacheString(localize(section.cta.text), {
                          fromPrice: formatAmount(phoneOffer.monthlyPrice.phone),
                          monthsShort: localize(monthsShort),
                      }),
            }}
            soldOut={!phoneOffer}
        />
    )
}
Section(PromoCard)
