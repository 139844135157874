import { Markdown } from "../../../../../../reactor"
import { fixedSpacingSizes } from "../../constants/sizes"
import { Flex } from "../base/Flex"
import { MarkdownView } from "../base/MarkdownView"
import { Heading } from "../typography/Heading"
import { Text } from "../typography/Text"
import { Icon, IconName } from "../visual/Icon"

type Step = { id: string; icon: IconName; text: string | Markdown }

export function NextStepsBlock(props: { heading?: string; steps: Step[] }) {
    return (
        <div>
            {!!props.heading && (
                <Heading level={3} margin={{ bottom: fixedSpacingSizes.sm }}>
                    {props.heading}
                </Heading>
            )}
            <Flex direction="column" gap={12}>
                {props.steps.map((s) => (
                    <Flex
                        key={s.id}
                        gap={fixedSpacingSizes.sm}
                        backgroundColor="gray100"
                        padding="md"
                        borderRadius="md"
                        alignItems="center"
                    >
                        <Icon icon={s.icon} size={32} />
                        <Text variant="body" size="md">
                            {typeof s.text === "string" ? s.text : <MarkdownView value={s.text} />}
                        </Text>
                    </Flex>
                ))}
            </Flex>
        </div>
    )
}
