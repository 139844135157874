import { useDefaultLocale, useWebPagesInfos } from "../../../studio/client"
import { useCurrentLocale, useLocalize } from "../../localization/client-side/useLocalize"
import { WebPage } from "../model/WebPage"

export function useWebPagesHrefs(pageIds: (WebPage["id"] | null)[] | null) {
    const pagesInfos = useWebPagesInfos({ ids: pageIds })
    const localize = useLocalize()
    const currentLocale = useCurrentLocale()
    const defaultLocale = useDefaultLocale().data?.defaultLocale
    const localePath = currentLocale === defaultLocale ? "" : `/${currentLocale}`
    const paths = pagesInfos.data?.map((item) =>
        !item
            ? item
            : {
                  title: localize(item.title),
                  path: item.slug ? `${localePath}/${localize(item.slug)}` : "/",
              }
    )

    return paths
}
