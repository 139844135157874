import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { Markdown } from "../../../../reactor"
import { Body } from "../ui/components/typography/Body"
import { BodySize } from "../ui/constants/sizes"
import { Text } from "../ui/components/typography/Text"

import { ContentSection, contentSectionCss } from "./Content"
import { MarkdownView } from "../ui/components/base/MarkdownView"
import { Color, colors } from "../ui/constants/colors"

interface TextContent extends ContentSection {
    /**
     * @default md
     */
    bodyTextSize: BodySize

    /**
     * @default gray500
     */
    bodyTextColor?: Color

    text: Localized<Markdown>
}

/**
 * @icon ui-align-left
 */
function TextContent(section: TextContent) {
    const localize = useLocalize()
    return (
        <div
            css={css(contentSectionCss(section), {
                // Workaround for emotions reluctance to allow :first-child selector.
                [`${["h1", "h2", "h3", "h4"].map((h) => `${h}:not(:first-of-type:not(style):not(:first-of-type ~ *))`)}`]:
                    {
                        marginTop: ".8em",
                    },
                a: {
                    color: colors.brand,
                    textDecoration: "none",
                },
                "a:hover": {
                    color: colors.brand,
                },
            })}
        >
            <Text variant="body" size={section.bodyTextSize}>
                <MarkdownView
                    value={localize(section.text)}
                    renderers={{
                        paragraph: (p) => (
                            <Body size={section.bodyTextSize} color={section.bodyTextColor}>
                                {p.children}
                            </Body>
                        ),
                    }}
                />
            </Text>
        </div>
    )
}

Section("TextContent", TextContent)
