import type DropinElement from "@adyen/adyen-web/dist/types/components/Dropin"
import type { DropinElementProps } from "@adyen/adyen-web/dist/types/components/Dropin/types"
import type { StylesObject } from "@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types"
import type Core from "@adyen/adyen-web/dist/types/core"
import type { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types"
import type { CoreOptions } from "@adyen/adyen-web/dist/types/core/types"
import { CustomTranslations } from "@adyen/adyen-web/dist/types/language/types"
import { useCallback, useEffect, useRef, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { Assert } from "../../../reactor/Helpers"
import {
    getPaymentMethods,
    postAdditionalDetails,
    postPayment,
    useMerchantConfig,
    usePaymentStatus,
} from "../../../studio/client"
import type { LocaleKey } from "../../localization/Locale"
import { useCurrentLocale } from "../../localization/client-side/useLocalize"
import { appEnvToWebEnv } from "../types/AdyenEnvironments"
import type { GetMerchantConfigDto, PaymentChannel } from "../types/dtos"
import { ErrorModal } from "./ErrorModal"

// Adyen result code source: https://docs.adyen.com/online-payments/build-your-integration/payment-result-codes/
const paymentSuccessful = ["Authorised"]
const paymentPending = ["Pending", "Received", "PresentToShopper"]
const orderStatusPaid = ["pending", "capturable", "complete", "refundable", "amendable", "faulty"]
const orderStatusSimpleError = ["inProgress", "refused"]

type AdyenFields = {
    adyenOrder: string
    adyenOrderId: string
    locale: LocaleKey
    merchantConfig: GetMerchantConfigDto
    setComplete: (complete: boolean) => void
    auth3ds?: boolean
    testCode?: number
    /**
     * Whether to show the "store details" checkbox in the Adyen component for
     * credit card payments.
     */
    enableStoreDetails?: boolean
}

export function AdyenComponent({
    onSuccess,
    onError,
    adyenOrder,
    adyenOrderId,
    enableStoreDetails,
    styles,
    translations,
    DropinElementProps,
    disableSuccessAnimation,
}: {
    onSuccess: () => void
    onError?: (err?: any) => void
    adyenOrder?: string
    adyenOrderId?: string
    styles?: StylesObject
    translations?: CustomTranslations
    DropinElementProps?: DropinElementProps
    disableSuccessAnimation?: boolean
    /**
     * Whether to show the "store details" checkbox in the Adyen component for
     * credit card payments.
     *
     * Note that for subscriptions, this shuold typically NOT be set to true,
     * since it is required to store the payment method for future payments, and
     * we do this by forcing it in the `IAdyenOrder.shouldStorePaymentMethod`
     * callback.
     */
    enableStoreDetails?: boolean
}) {
    const [searchParams] = useSearchParams()
    const routeAdyenOrder = useParams().adyenOrder
    const routeAdyenOrderId = useParams().adyenOrderId
    adyenOrder = Assert(adyenOrder ?? routeAdyenOrder)
    adyenOrderId = Assert(adyenOrderId ?? routeAdyenOrderId)
    const locale = useCurrentLocale()
    const { data: paymentStatus } = usePaymentStatus(adyenOrder, adyenOrderId)
    const { data: merchantConfig } = useMerchantConfig(adyenOrder, adyenOrderId)
    const [errorDescription, setErrorDescription] = useState<string | undefined>()
    const adyenContainer = useRef(null)
    const { auth3ds, testCode } = getSearchParams(searchParams)
    const [adyenComponent, setComponent] = useState<DropinElement | undefined>(undefined)
    const [complete, setComplete] = useState<boolean>(false)

    const handleError = useCallback(
        (err?: any) =>
            onError
                ? onError(err)
                : void ErrorModal([
                      "We could not process your payment at this time.",
                      "Please try again in a few minutes.",
                  ]),
        [onError]
    )

    useEffect(() => {
        // This flag counteracts double rendering of the Adyen component, which causes issues.
        // Source: https://github.com/adyen-examples/adyen-react-online-payments/blob/main/src/features/payment/Payment.js#L66
        let renderFlag = true
        const shouldLoadAdyen =
            adyenContainer.current !== null &&
            merchantConfig !== undefined &&
            adyenOrder !== undefined &&
            adyenOrderId !== undefined
        if (!shouldLoadAdyen) return

        const adyenFields: AdyenFields = {
            adyenOrder,
            adyenOrderId,
            locale,
            merchantConfig,
            setComplete,
            auth3ds,
            testCode,
            enableStoreDetails,
        }
        import("../../../node_modules/@adyen/adyen-web/dist/adyen.css")
        import("./adyen.css")

        loadAdyen(adyenFields, styles, translations ?? {}, handleError)
            .then((adyenObject) => {
                if (adyenContainer.current !== null && adyenObject !== undefined && renderFlag) {
                    setComponent(
                        adyenObject
                            .create("dropin", { ...(DropinElementProps ?? {}) })
                            .mount(adyenContainer.current)
                    )
                }
            })
            .catch(handleError)

        return () => {
            renderFlag = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentStatus, merchantConfig, enableStoreDetails])

    useEffect(() => {
        const paymentMade = orderStatusPaid.includes(paymentStatus?.status ?? "") || complete
        const paymentFailed = orderStatusSimpleError.includes(paymentStatus?.status ?? "")
        if (paymentMade && adyenComponent !== undefined && disableSuccessAnimation) {
            onSuccess()
        } else if (paymentMade && adyenComponent !== undefined && !disableSuccessAnimation) {
            adyenComponent.setStatus("success")
            setTimeout(() => onSuccess(), 2000)
        } else if (paymentFailed)
            setErrorDescription("There was an error processing your previous payment.")
    }, [adyenComponent, paymentStatus, complete, disableSuccessAnimation, onSuccess])

    return (
        <>
            {errorDescription && (
                <div className="fuse-adyen-package error-description">{errorDescription}</div>
            )}
            <div className="fuse-adyen-package dropin-container" ref={adyenContainer}></div>
        </>
    )
}

function getSearchParams(searchParams: URLSearchParams) {
    let auth3ds
    let testCode
    if (searchParams.get("auth3ds") === "true") auth3ds = true
    const parsedInt = parseInt(searchParams.get("testCode") ?? "")
    if (!isNaN(parsedInt)) testCode = parsedInt
    return { auth3ds, testCode }
}

async function loadAdyen(
    adyenFields: AdyenFields,
    styles: StylesObject = {},
    translations: CustomTranslations,
    onError: (err?: any) => void
) {
    // Seems the typing is wrong in the Adyen NPM package, hence this ugly cast
    const AdyenCheckout = (await import("@adyen/adyen-web")) as any as (
        props: CoreOptions
    ) => Promise<Core>
    const { adyenOrder, adyenOrderId, locale, merchantConfig } = adyenFields
    try {
        const response = await getPaymentMethods(adyenOrder, adyenOrderId, locale, "Web")
        return AdyenCheckout({
            amount: response.amount,
            paymentMethodsResponse: response.result as PaymentMethodsResponse,
            clientKey: merchantConfig.adyenClientKey,
            locale: response.adyenLocale,
            environment: appEnvToWebEnv(merchantConfig.adyenEnvironment),
            onSubmit: onSubmit(adyenFields, onError),
            onAdditionalDetails: onAdditionalDetails(adyenFields, onError),
            translations,
            paymentMethodsConfiguration: {
                card: {
                    styles,
                    enableStoreDetails: adyenFields.enableStoreDetails,
                    hasHolderName: true,
                    holderNameRequired: true,
                },
            },
        })
    } catch (_error) {
        onError(_error)
    }
}

function onSubmit(adyenFields: AdyenFields, onError: (err?: any) => void) {
    const { adyenOrder, adyenOrderId, locale, auth3ds, testCode } = adyenFields
    return async (state: any, adyenComponent: any) => {
        if (!state?.isValid) {
            onError()
            return
        }

        try {
            const body = {
                locale,
                adyenData: state?.data,
                channel: "Web" as PaymentChannel,
                auth3ds,
                testCode,
            }
            const response = await postPayment(adyenOrder, adyenOrderId, body)
            handleAdyenResponse(adyenComponent, response.result, adyenFields, onError)
        } catch (_error) {
            onError(_error)
        }
    }
}

function onAdditionalDetails(adyenFields: AdyenFields, onError: (err?: any) => void) {
    return async (state: any, adyenComponent: any) => {
        try {
            const response = await postAdditionalDetails(
                adyenFields.adyenOrder,
                adyenFields.adyenOrderId,
                state?.data
            )
            handleAdyenResponse(adyenComponent, response.result, adyenFields, onError)
        } catch (_error) {
            onError(_error)
        }
    }
}

function handleAdyenResponse(
    adyenComponent: any,
    response: any,
    adyenFields: AdyenFields,
    onError: (err?: any) => void
) {
    const resultCode = response?.resultCode
    const paymentMade =
        paymentSuccessful.includes(resultCode) || paymentPending.includes(resultCode)

    if (response?.action !== undefined) adyenComponent?.handleAction(response?.action)
    else if (paymentMade) adyenFields.setComplete(true)
    else onError()
}
