import { useCallback, useContext, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import { css } from "../ui/helpers/css"
import { useHeader, useMe } from "../client"
import { useDefaultLocale, useLocales, useWebPageInfo } from "../../../../studio/client"
import { Section } from "../../../../packages/editing/Section"
import { LocaleKey } from "../../../../packages/localization/Locale"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { CurrentLocaleContext } from "../../../../packages/localization/client-side/CurrentLocaleContext"
import { colors } from "../ui/constants/colors"
import { Logo } from "../ui/components/visual/Logo"
import { Flex } from "../ui/components/base/Flex"
import { Icon, IconName } from "../ui/components/visual/Icon"
import { Button, responsiveButtonCss } from "../ui/components/buttons/Button"
import { TrustpilotButton } from "../ui/components/buttons/TrustpilotButton"
import { MenuItem } from "../../model/Header"
import {
    responsiveBorderRadius,
    responsiveCss,
    responsiveHeadingSize,
    scaleValue,
} from "../ui/helpers/css"
import { LocaleSelect } from "../ui/components/controllers/LocaleSelect"
import { NavigationModal } from "../ui/components/modal/NavigationModal"
import { Text } from "../ui/components/typography/Text"
import { WebPageContext } from "../../../../packages/web/components/WebPage"
import { Url, Uuid } from "../../../../reactor"
import { useLogInWithVipps } from "../../../../packages/oidc/code-flow/VippsLogin"
import { MustacheString } from "../../../../packages/editing/Mustache"

/**
 * The header for the site. Renders the first item separate from the rest, as they are
 * to be rendered in a hamburger popup menu on mobile, while the first item should be visible
 * outside of the popup menu.
 *
 * @icon ui-menu-01
 */
function Header(section: { readonly id: Uuid<"Section">; menuItems?: MenuItem[] }) {
    const webPageContext = useContext(WebPageContext)
    const locales = useLocales()
    const localize = useLocalize()
    const header = useHeader(webPageContext.id, section.id as any as Uuid<"Section">)
    const { locale: currentLocale, setLocale } = useContext(CurrentLocaleContext)
    const defaultLocale = useDefaultLocale().data?.defaultLocale
    const menuItems = useMemo(
        () =>
            header.data?.items.map((item) => {
                const localePath = currentLocale === defaultLocale ? "" : `/${currentLocale}`
                const path = item.slug ? `${localePath}/${localize(item.slug)}` : "/"
                const fragment = item.section ? `#${item.section}` : ""
                const href = item.url && !item.slug ? item.url : Url(`${path}${fragment}`)

                // During pre launch period, fragments and external links (items with url property)
                // should work as intended, while other links should trigger open waiting list
                // modal.
                return {
                    id: item.id.valueOf(),
                    href: (item.slug || item.section || item.url) && href ? href : undefined,
                    text: localize(item.text),
                    icon: item.icon,
                }
            }) || [],
        [header.data?.items, currentLocale, defaultLocale, localize]
    )

    const handleLocaleChange = useCallback(
        (l: LocaleKey) => {
            if (setLocale) {
                setLocale(l)
            }
        },
        [setLocale]
    )

    const me = useMe()
    const location = useLocation()
    const accountPageInfo = useWebPageInfo(header.data?.account?.page ?? null)
    const loginWithVipps = useLogInWithVipps(
        accountPageInfo.data?.slug ? "/" + localize(accountPageInfo.data.slug) : "/",
        location.pathname // Go back where we came from if login fails
    )

    const renderUserButton = useCallback(() => {
        return me.data && !me.data.anonymous ? (
            <div>
                <Button
                    variant="profile"
                    size="sm"
                    onClick={
                        accountPageInfo.data?.slug
                            ? undefined
                            : () => alert("Missing account page config in header config.")
                    }
                    href={
                        accountPageInfo.data?.slug
                            ? `/${localize(accountPageInfo.data.slug)}`
                            : undefined
                    }
                    iconStart={header.data?.account?.icon}
                >
                    {MustacheString(localize(header.data?.account?.text ?? { no: "" }), {
                        givenName: me.data.givenName ?? "",
                        familyName: me.data.familyName ?? "",
                    })}
                </Button>
            </div>
        ) : (
            <Button
                variant="dark"
                size="sm"
                onClick={() => {
                    void loginWithVipps()
                }}
            >
                <Text variant="heading" level="4">
                    {localize(header.data?.login?.text ?? {})}
                </Text>
            </Button>
        )
    }, [
        accountPageInfo.data?.slug,
        header.data?.account?.icon,
        header.data?.account?.text,
        header.data?.login?.text,
        localize,
        loginWithVipps,
        me.data,
    ])

    if (header.loading || !header.data) {
        return <></>
    }

    return (
        <Flex as="nav" alignItems="center" css={css({ height: scaleValue(100) })}>
            <Flex alignItems="center" style={{ width: "100%" }}>
                <Link to={`/${currentLocale === defaultLocale ? "" : currentLocale}`}>
                    <Logo
                        css={css(
                            {
                                width: scaleValue(114),
                                height: "auto",
                                marginRight: scaleValue(8),
                            },
                            responsiveCss("min", "md", { marginRight: scale16 })
                        )}
                    />
                </Link>
                {header.data.trustpilot ? (
                    <div css={css(responsiveCss("max", "sm", { display: "none" }))}>
                        <TrustpilotButton
                            margin={{ right: scale16 }}
                            href={header.data.trustpilot.url}
                            score={header.data.trustpilot.score.valueOf()}
                        />
                    </div>
                ) : null}
                <Flex css={css(responsiveCss("max", "lg", { display: "none" }))}>
                    {!!menuItems[0] && <MenuItem {...menuItems[0]} />}
                    {menuItems.slice(1, menuItems.length).map((item) => (
                        <MenuItem key={item.id} {...item} />
                    ))}
                </Flex>
            </Flex>
            <div css={css(responsiveCss("max", "lg", { display: "none" }))}>
                {header.data.login ? (
                    <Flex gap={8}>
                        <LocaleSelect
                            locales={locales.data || []}
                            currentLocale={currentLocale}
                            onLocaleChange={handleLocaleChange}
                        />
                        {renderUserButton()}
                    </Flex>
                ) : null}
            </div>
            <Flex gap={6} css={css(responsiveCss("min", "xl", { display: "none" }))}>
                {me.data?.anonymous
                    ? !!menuItems[0] && <MenuItem {...menuItems[0]} />
                    : renderUserButton()}
                <NavigationModal
                    items={menuItems}
                    renderUserButton={renderUserButton}
                    isLoggedIn={!me.data?.anonymous}
                    trustpilot={
                        header.data.trustpilot
                            ? {
                                  score: header.data.trustpilot.score,
                                  text: localize(header.data.trustpilot.text),
                                  url: header.data.trustpilot.url,
                              }
                            : undefined
                    }
                    locales={locales.data || []}
                    currentLocale={currentLocale}
                    onLocaleChange={handleLocaleChange}
                />
            </Flex>
        </Flex>
    )
}

function MenuItem(props: { href?: Url; text: string; icon?: IconName; onClick?: () => void }) {
    return (
        <Flex css={css(spacer)}>
            <Flex
                as="a"
                href={props.href?.valueOf()}
                padding={{ y: scaleValue(12), x: scale16 }}
                alignItems="center"
                css={menuItemCss}
                onClick={props.onClick}
            >
                {props.icon && <Icon icon={props.icon} margin={{ right: scaleValue(8) }} />}
                {props.text}
            </Flex>
        </Flex>
    )
}

const menuItemCss = css([
    {
        cursor: "pointer",
        height: scaleValue(48),
        textDecoration: "none",
        whiteSpace: "nowrap",
    },
    responsiveHeadingSize("4"),
    responsiveCss(
        "max",
        "lg",
        css(responsiveButtonCss("secondary", "sm", {}), responsiveBorderRadius("lg"))
    ),
])

const scale16 = scaleValue(16)

const spacer = css(
    {
        alignItems: "center",
    },
    responsiveCss("min", "xl", {
        ":after": {
            content: '""',
            width: 1,
            height: 20,
            backgroundColor: colors.gray200,
            marginLeft: scale16,
            marginRight: scale16,
        },
        ":last-of-type:after": {
            display: "none",
        },
    })
)

Section("Header", Header)
