import { Heading } from "../typography/Heading"
import { Icon, IconName } from "../visual/Icon"
import { Text } from "../typography/Text"
import { Divider } from "../other/Divider"
import { Markdown } from "../../../../../../reactor"
import { Flex } from "../base/Flex"
import { scaleValue } from "../../helpers/css"

export function BenefitsBlock(props: {
    heading: string
    items: { id: string; icon: IconName; heading: string; text: string | Markdown }[]
}) {
    return (
        <div>
            <Heading level={2} margin={{ bottom: "md" }}>
                {props.heading}
            </Heading>
            <Flex
                direction="column"
                padding="md"
                borderColor="gray200"
                style={{ borderRadius: 16 }}
            >
                {props.items.map((item, index) => (
                    <Flex direction="column" key={item.id}>
                        {index > 0 && <Divider horizontal spacing={"md"} />}
                        <Icon size={32} icon={item.icon} margin={{ bottom: scaleValue(16) }} />
                        <Text variant="body" size="lg" margin={{ bottom: scaleValue(8) }}>
                            {item.heading}
                        </Text>
                        <Text variant="body" size="md" color="gray300">
                            {item.text}
                        </Text>
                    </Flex>
                ))}
            </Flex>
        </div>
    )
}
