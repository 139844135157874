import { createContext, useContext } from "react"
import { GetInsuranceOptionsDto, PhoneOffer, PhoneOrderPrice } from "../../client"
import { InsuranceStep, InsuranceStepProps } from "./InsuranceStep"
import { LoginStep, LoginStepProps } from "./LoginStep"
import { PaymentStep, PaymentStepProps } from "./PaymentStep"
import { ShippingStep, ShippingStepProps } from "./ShippingStep"
import { TradeInStep, TradeInStepProps } from "./TradeInStep"
import { IconName } from "../../ui/components/visual/Icon"
import { SSRResource } from "../../../../../packages/ssr/SSRHook"
import { CheckoutOrderState, CheckoutShippingState, SoldOutProps } from "../Checkout"

export const checkoutSteps = {
    TradeIn: TradeInStep,
    Insurance: InsuranceStep,
    Login: LoginStep,
    Shipping: ShippingStep,
    Payment: PaymentStep,
}

export type CheckoutStep = keyof typeof checkoutSteps

export const checkoutNavigation: { id: CheckoutStep; icon: IconName }[] = [
    {
        id: "TradeIn",
        icon: "handCoins",
    },
    {
        id: "Insurance",
        icon: "shield",
    },
    {
        id: "Login",
        icon: "vipps",
    },
    {
        id: "Shipping",
        icon: "truck",
    },
    {
        id: "Payment",
        icon: "receipt",
    },
]

export type CheckoutState = {
    currentStep: CheckoutStep
    availableSteps: CheckoutStep[]
    termsAccepted: boolean
    marketingAccepted: boolean
}

export type CheckoutContext = {
    props: {
        TradeIn: TradeInStepProps
        Insurance: InsuranceStepProps
        Login: LoginStepProps
        Shipping: ShippingStepProps
        Payment: PaymentStepProps
        SoldOut: SoldOutProps
    }
    offer: PhoneOffer
    setOffer: (offer: PhoneOffer) => void
    shipping: CheckoutShippingState
    setShipping: (value: CheckoutShippingState) => void
    insuranceOptions: SSRResource<GetInsuranceOptionsDto[]>
    price?: PhoneOrderPrice
    step: CheckoutStep
    setStep: (step: CheckoutStep) => void
    skipAuthStep: boolean
    useOrder: CheckoutOrderState
}

export const CheckoutContext = createContext<CheckoutContext | undefined>(undefined)

export function useCheckoutContext(): CheckoutContext {
    const context = useContext(CheckoutContext)
    if (!context) throw new Error("CheckoutContext not found")
    return context
}
