import { Image, Markdown } from "../../../../../../reactor"
import { colors } from "../../constants/colors"
import { css, responsiveCss, scaleValue } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { ResponsiveImage } from "../base/ResponsiveImage"
import { Heading } from "../typography/Heading"
import { Text } from "../typography/Text"

export function StepsBlock(props: {
    heading: string
    items: { id: string; text: string | Markdown }[]
    image?: Image
}) {
    return (
        <Flex as="section" direction="column" gap={scaleValue(24)}>
            <Heading level={2}>{props.heading}</Heading>
            <Flex direction="column" gap={[8, ["min", "md", 12]]}>
                {props.items.map((item, index) => (
                    <Flex
                        key={item.id}
                        gap={24}
                        backgroundColor="gray100"
                        padding="md"
                        style={{ borderRadius: 16 }}
                        alignItems="center"
                    >
                        <Flex
                            backgroundColor="gray500"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                                flexShrink: 0,
                                color: colors.grayWhite,
                                borderRadius: 100,
                            }}
                            css={css(
                                { width: 24, height: 24 },
                                responsiveCss("min", "md", {
                                    height: scaleValue(48),
                                    width: scaleValue(48),
                                })
                            )}
                        >
                            <Text variant="heading" level={["5", ["min", "md", "4"]]}>
                                {index + 1}
                            </Text>
                        </Flex>
                        <Text variant="body" size="md">
                            {item.text}
                        </Text>
                    </Flex>
                ))}
            </Flex>
            {props.image && (
                <ResponsiveImage
                    image={props.image}
                    width={560}
                    style={{ borderRadius: 16, width: "100%" }}
                />
            )}
        </Flex>
    )
}
