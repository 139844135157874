import { useDefaultLocale } from "../../../studio/client"
import { CurrentLocaleContext } from "../../localization/client-side/CurrentLocaleContext"
import { useLocalize } from "../../localization/client-side/useLocalize"
import { useContext } from "react"
import { Localized } from "../../localization/Localized"

/**
 * Hook to get a localized path based on a slug and the current locale. Conforms to the routing
 * pattern in the Web package where route paths to a slug in context of the default locale is
 * `/localized-slug` and in other locales `/locale-key/localized-slug`.
 */
export function useLocalizeSlug() {
    const localize = useLocalize()
    const defaultLocale = useDefaultLocale().data?.defaultLocale
    const { locale } = useContext(CurrentLocaleContext)
    const localePath = locale === defaultLocale ? "" : `/${locale}`

    return (slug?: Localized<string>) => (slug ? `${localePath}/${localize(slug)}` : "/")
}
