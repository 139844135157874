import { css } from "@emotion/react"
import { SpacingSize } from "../ui/constants/sizes"
import { spacingToCss } from "../ui/components/base/Box"

/**
 * ContentSection is not a section, but a base type for other sections that is used to
 * present content.
 */
export type ContentSection = {
    /**
     * The maximum width of this section.
     */
    maxWidth?: number

    /**
     * Margin to other elements or on the sides.
     */
    margin?: {
        top?: SpacingSize
        bottom?: SpacingSize
        left?: SpacingSize
        right?: SpacingSize
    }
}

export function contentSectionCss(section: ContentSection & Record<string, any>) {
    return css(section.margin && spacingToCss("margin", section.margin), {
        "li::marker": { fontWeight: 600 },
        maxWidth: section.maxWidth,
    })
}
