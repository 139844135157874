import { css } from "@emotion/react"
import { EditableSections } from "../../../../packages/editing/EditableSections"
import { Section } from "../../../../packages/editing/Section"
import { ScreenSize, SpacingSize } from "../ui/constants/sizes"
import { responsiveCss, responsiveSpacing } from "../ui/helpers/css"
import { ContentSection, contentSectionCss } from "./Content"

interface ContentGroupProps extends ContentSection {
    /**
     * @expand
     */
    contents: {
        /**
         * Adding more than one section here is not supported, but a single section fields are not
         * supported in Studio, so that's why it's a list, so add more content instead.
         *
         * @expand
         */
        sections: Section[]
        /**
         * Content will be hidden on the selected screen size and smaller.
         */
        hideBreakpoint?: ScreenSize
    }[]

    /**
     * @default sm
     */
    stackingBreakpoint?: ScreenSize

    /**
     * How big gap between the sections.
     */
    gap?: SpacingSize
}

Section(ContentGroup)
/**
 * Combine a group of content sections side by side and/or stacked.
 *
 * @icon ui-columns-01
 */
function ContentGroup(section: ContentGroupProps) {
    const sections = (
        <EditableSections
            sections={section.contents.flatMap((c) => c.sections)}
            itemCss={(index) =>
                section.contents[index]?.hideBreakpoint
                    ? responsiveCss("max", section.contents[index].hideBreakpoint, {
                          display: "none !important",
                      })
                    : css()
            }
        />
    )

    return (
        <div
            css={css(
                contentSectionCss(section),
                {
                    display: "grid",
                    gridAutoFlow: "column",
                    gridAutoColumns: "minmax(0, 1fr)",
                },
                section.gap && responsiveSpacing(section.gap, "gap"),
                section.stackingBreakpoint &&
                    responsiveCss("max", section.stackingBreakpoint, {
                        gridAutoFlow: "row",
                        gridAutoRows: "auto",
                    })
            )}
        >
            {sections}
        </div>
    )
}
