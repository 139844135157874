import { Fragment, useContext, useMemo } from "react"
import { css } from "@emotion/react"
import { Link } from "react-router-dom"
import { WebPageContext } from "../../../../packages/web/components/WebPage"
import { Section } from "../../../../packages/editing/Section"
import { useDefaultLocale } from "../../../../studio/client"
import { Url } from "../../../../reactor"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../../packages/localization/client-side/useLocalize"
import { colors } from "../ui/constants/colors"
import { pageWidthCss, responsiveCss } from "../ui/helpers/css"
import { Text } from "../ui/components/typography/Text"
import { Flex } from "../ui/components/base/Flex"

/**
 * @icon ui-slash-divider
 */
function Breadcrumb(section: {}) {
    const pageContext = useContext(WebPageContext)
    const localize = useLocalize()
    const currentLocale = useCurrentLocale()
    const defaultLocale = useDefaultLocale().data?.defaultLocale

    const items = useMemo(
        () =>
            pageContext.breadcrumb.map((item) => {
                const localePath = currentLocale === defaultLocale ? "" : `/${currentLocale}`
                const path = item.slug ? `${localePath}/${localize(item.slug)}` : "/"
                const href = Url(path)
                return { ...item, href }
            }),
        [currentLocale, defaultLocale, localize, pageContext.breadcrumb]
    )

    return (
        <Flex
            justifyContent="center"
            css={css({
                borderTop: `1px solid ${colors.gray200}`,
                borderBottom: `1px solid ${colors.gray200}`,
                width: "100vw",
                marginLeft: "calc(50% - 50vw)",
            })}
        >
            <div
                style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                }}
                css={pageWidthCss()}
            >
                <Text
                    variant="body"
                    size="md"
                    color="gray300"
                    css={responsiveCss("min", "md", { fontWeight: 500 })}
                >
                    <Flex gap={8}>
                        {items.map((page, index) => (
                            <Fragment key={page.id.valueOf()}>
                                {index > 0 && "/"}{" "}
                                <div
                                    style={
                                        index === pageContext.breadcrumb.length - 1
                                            ? { color: colors.gray500 }
                                            : {}
                                    }
                                >
                                    {index < items.length - 1 ? (
                                        <Link
                                            to={page.href.valueOf()}
                                            style={{ textDecoration: "none" }}
                                        >
                                            {localize(page.title)}
                                        </Link>
                                    ) : (
                                        localize(page.title)
                                    )}
                                </div>
                            </Fragment>
                        ))}
                    </Flex>
                </Text>
            </div>
        </Flex>
    )
}
Section("Breadcrumb", Breadcrumb)
