import { Flex } from "../base/Flex"
import { Button } from "../buttons/Button"
import { Heading } from "../typography/Heading"
import { Icon, IconName } from "../visual/Icon"
import { Text } from "../typography/Text"
import { scaleValue } from "../../helpers/css"
import { Divider } from "../other/Divider"
import { ReactNode } from "react"
import { LabelNotification } from "../notification/LabelNotification"

export function CheckoutStepSummaryBlock(props: {
    step: string
    icon: IconName
    title?: string
    subtitle?: string
    text?: string | ReactNode
    price?: string
    originalPrice?: string
    label?: string
    cta?: {
        text: string
        onClick: () => void
    }
    list?: { id: string; text: string; icon?: IconName }[]
}) {
    return (
        <Flex borderRadius="md" padding="md" borderColor="gray200" direction="column" gap={16}>
            <Flex justifyContent="space-between" alignItems="center">
                <Flex gap={8} alignItems="center">
                    <Icon icon={props.icon} /> <Heading level={4}>{props.step}</Heading>
                </Flex>
                {!!props.label && (
                    <LabelNotification size={["fixed", "md", "md"]} color="gray500">
                        {props.label}
                    </LabelNotification>
                )}
                {!!props.cta && (
                    <Button size="sm" variant="secondary" onClick={() => alert("Not implemented")}>
                        {props.cta.text}
                    </Button>
                )}
            </Flex>
            <div>
                {!!props.title && <Heading level={3}>{props.title}</Heading>}

                {!!props.price && (
                    <Flex alignItems="baseline" gap={2}>
                        {!!props.originalPrice && (
                            <Text
                                variant="body"
                                size="md"
                                color="gray300"
                                style={{
                                    textDecoration: "underline",
                                    textUnderlineOffset: "-5px",
                                    textDecorationSkipInk: "none",
                                }}
                            >
                                {props.originalPrice}
                            </Text>
                        )}
                        <Text variant="heading" level="3" color="brand">
                            {props.price}
                        </Text>
                    </Flex>
                )}
                {!!props.subtitle && (
                    <Text variant="body" size="md" color="gray300" margin={{ top: 8 }}>
                        {props.subtitle}
                    </Text>
                )}
            </div>
            {!!props.list?.length && (
                <>
                    <Divider horizontal spacing={0} />
                    <Flex direction="column" gap={scaleValue(8)}>
                        {props.list.map((li) => (
                            <Flex key={li.id} gap={scaleValue(8)}>
                                <Icon icon={li.icon ?? "check"} color="forest" />
                                <Text variant="body" size="md">
                                    {li.text}
                                </Text>
                            </Flex>
                        ))}
                    </Flex>
                </>
            )}
            {!!props.text && (
                <>
                    <Divider horizontal spacing={0} />
                    <Flex direction="column" gap={scaleValue(8)}>
                        <Text variant="body" size="md">
                            {props.text}
                        </Text>
                    </Flex>
                </>
            )}
        </Flex>
    )
}
